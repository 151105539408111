.about
  background-color: var(--color-bg)

.limiter
  display: grid
  grid-template-rows: 1fr
  gap: 80px

.about__item p
  margin: 0
  font-size: calc(18px + 7 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 500
  //max-width: 100%

.about__item
  color: var(--color-white-80)
  display: grid
  grid-auto-flow: row
  gap: 30px
  font-size: calc(18px + 7 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 500

  ul li
    display: grid
    grid-template-columns: max-content 1fr
    justify-content: start
    gap: 25px
    align-items: center
    font-size: calc(16px + 9 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 500

    &::before
      content: ""
      background-color: var(--color-base)
      display: inline-block
      width: 10px
      height: 10px
      border-radius: 50%

    & > ul
      grid-column: 1 / -1

  a
    color: var(--color-base)

.about h2
  margin: 0
  color: var(--color-base)
  font-size: calc(25px + 15 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 700
  line-height: normal
  text-transform: uppercase

.about ul
  list-style: none
  display: grid
  grid-auto-flow: row
  gap: 20px

.about ol
  li
    font-size: calc(16px + 9 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 500

.about table
  font-size: calc(9px + 9 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 300


.about__button
  margin-top: 80px

.limiter
  overflow: hidden
  position: relative
  color: black
  transition: max-height 0.3s ease

  .bottom
    bottom: 0
    width: 100%
    height: 60px
    opacity: 1
    transition: 0.3s

.read-more-checker
  opacity: 0
  position: absolute

  &:checked
    ~ .limiter
      max-height: none

      .bottom
        opacity: 0
        transition: 0.3s

    ~ .read-more-button:before
      content: "Свернуть «"

  ~ .read-more-button:before
    content: "Развернуть »"

.read-more-button
  display: grid
  cursor: pointer

  /*display: inline-block;*/
  color: #777
  border-radius: 20px
  background: var(--color-base)
  text-transform: uppercase

/* Фиксируем шрифты для больших экранов */
@media (min-width: 1921px)
  .about p, .about ul li
    font-size: 25px

  .about h2
    font-size: 40px

@media (max-width: 1000px)
  .about__button
    margin-top: 50px

  .about__ul
    padding: 0

  .limiter
    gap: 50px

@media(max-width: 560px)
  .about ul
    padding: 0

  .about ul li
    gap: 10px

//.description
//  background-color: black
//  color: white
//  font-size: 14px
//  font-weight: 400
//  padding: 30px 0 10px 0
//
//  &#{&} ul
//    margin: 0
//
//
//  &#{&} li
//    padding-bottom: 10px
//
//    &::marker
//      color: #F79817
//      font-size: 20px
//      text-align: end
//
//
//  &__static
//    padding: 10px 0
//
//  &__title
//    font-size: 25px
//    font-weight: 600
//    color: #F79817
//    text-transform: uppercase
//    padding: 10px 0
//
//  &__dropup
//    background-color: #F79817
//    padding: 20px
//    font-size: 20px
//    font-weight: 600
//    text-transform: uppercase
//    color: black
//    width: 150px
//    text-align: center
//    border-radius: 5px
//    margin: 30px auto 30px auto
//    cursor: pointer
//
//    &:hover
//      color: white
//
//.mobile_hide
//  display: none
//
//
