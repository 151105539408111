// Fonts Montserrat ================================================

//Light
@font-face
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 300
  src: local("Montserrat Light"), url('/assets/fonts/Montserrat-Light.ttf') format("truetype")

//Regular
@font-face
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 400
  src: local("Montserrat Regular"), url('/assets/fonts/Montserrat-Regular.ttf') format("truetype")

//Medium
@font-face
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 500
  src: local("Montserrat Medium"), url('/assets/fonts/Montserrat-Medium.ttf') format("truetype")

//SemiBold
@font-face
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 600
  src: local("Montserrat SemiBold"), url('/assets/fonts/Montserrat-SemiBold.ttf') format("truetype")

//Bold
@font-face
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 700
  src: local("Montserrat Bold"), url('/assets/fonts/Montserrat-Bold.ttf') format("truetype")

//ExtraBold
@font-face
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 800
  src: local("Montserrat ExtraBold"), url('/assets/fonts/Montserrat-ExtraBold.ttf') format("truetype")

//ExtraBold
@font-face
  font-family: 'Montserrat'
  font-style: normal
  font-weight: 900
  src: local("Montserrat Black"), url('/assets/fonts/Montserrat-Black.ttf') format("truetype")

//Fonts Inter ==============================================

//Light
@font-face
  font-family: 'Inter'
  font-style: normal
  font-weight: 300
  src: local("Inter Light"), url('/assets/fonts/Inter-Light.ttf') format("truetype")

//Regular
@font-face
  font-family: 'Inter'
  font-style: normal
  font-weight: 400
  src: local("Inter Regular"), url('/assets/fonts/Inter-Regular.ttf') format("truetype")

//Medium
@font-face
  font-family: 'Inter'
  font-style: normal
  font-weight: 500
  src: local("Inter Medium"), url('/assets/fonts/Inter-Medium.ttf') format("truetype")

//SemiBold
@font-face
  font-family: 'Inter'
  font-style: normal
  font-weight: 600
  src: local("Inter SemiBold"), url('/assets/fonts/Inter-SemiBold.ttf') format("truetype")

//Bold
@font-face
  font-family: 'Inter'
  font-style: normal
  font-weight: 700
  src: local("Inter Bold"), url('/assets/fonts/Inter-Bold.ttf') format("truetype")

//ExtraBold
@font-face
  font-family: 'Inter'
  font-style: normal
  font-weight: 800
  src: local("Inter ExtraBold"), url('/assets/fonts/Inter-ExtraBold.ttf') format("truetype")

//ExtraBold
@font-face
  font-family: 'Inter'
  font-style: normal
  font-weight: 900
  src: local("Inter Black"), url('/assets/fonts/Inter-Black.ttf') format("truetype")
