.banner-vacancy
    background-color: var(--color-bg)
    padding: 0 0 80px 0
    //padding: calc(var(--offset) + var(--header-height)) 0 80px 0 /*Нужен без крошек*/

    .banner__header
        margin-bottom: 80px

    .title__bg
        //font-family: Inter
        letter-spacing: unset

    .banner__title
        color: var(--color-base-60)
        font-family: Inter
        font-size: calc(15px + 15 * ((100vw - 375px) / (1920 - 375)))
        font-style: normal
        font-weight: 900
        line-height: normal
        text-transform: uppercase

    .banner__item
        display: grid
        grid-template-columns: repeat(2, max-content)
        justify-content: space-between
        height: 100%
        padding: 0
        box-sizing: border-box
        overflow: hidden
        background-color: unset
        border-radius: 0
        aspect-ratio: unset

    .banner__block
        padding: 0
        display: grid
        position: relative
        grid-template-rows: repeat(2, max-content)
        gap: 40px
        height: 100%
        align-items: start
        z-index: 1

    .banner__item__ul
        padding: 0
        display: grid
        grid-auto-flow: row
        gap: 20px

        li > a
            display: grid
            grid-auto-flow: column
            justify-content: start
            gap: 15px
            align-items: start
            color: var(--color-base)
            font-family: Inter
            font-size: calc(12px + 11 * ((100vw - 375px) / (1920 - 375)))
            font-style: normal
            font-weight: 400
            line-height: normal
            text-decoration-line: underline

            &::before
                content: ""
                background-color: var(--color-base)
                display: inline-block
                width: 15px
                height: 15px
                border-radius: 50%
                align-self: start
                margin-top: 0.6em
                transform: translateY(-50%)

    .banner__image
        position: unset
        width: 100%
        height: 100%
        object-fit: cover
        z-index: 0

    img
        border-radius: 20px
        border: 1px solid var(--color-base)

/* Фиксируем шрифты для больших экранов
@media (min-width: 1921px)

@media (max-width: 1300px)
    .banner-vacancy .banner__item
        grid-template-columns: 1fr 1fr
        gap: 30px

@media(max-width: 900px)
    .banner-vacancy .banner__block
        gap: 20px
    .banner-vacancy .banner__item__ul
        gap: 12px
    .banner-vacancy .banner__header
        margin-bottom: 50px


@media(max-width: 700px)
    .banner-vacancy
        padding-bottom: 40px

    .banner-vacancy .banner__item
        grid-template-columns: max-content

    .banner-vacancy .banner__block
        gap: 15px

    .banner-vacancy .banner__item__ul
        gap: 10px

    .banner-vacancy .banner__image
        display: none

    .banner-vacancy .banner__item__ul li > a
        &::before
            width: 10px
            height: 10px


@media(max-width: 560px)
    .banner-vacancy .banner__header
        margin-bottom: 40px

    .banner-vacancy .banner__title
        margin: 0


@media(max-width: 450px)
    .banner-vacancy
        padding: 0 0 30px

    .banner-vacancy .title__bg
        max-width: calc(100vw - 40px)
        text-align: center

    .banner-vacancy .banner__header
        margin-bottom: 20px

    .banner-vacancy .banner__item__ul
        gap: 8px

    .banner-vacancy .banner__item__ul li > a
        &::before
            width: 8px
            height: 8px

    .banner-vacancy .banner__block
        gap: 10px