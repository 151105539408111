// Custome style

// Vars
//\:root
//    --container: 1216px
//    box-sizing: border-box
// Body

body
    min-height: 100vh
    font-family: 'Montserrat'
    margin: 0
    padding: 0
    font-size: 18px
    font-weight: 400
    color: var(--color-black)


.page-wrapper
    min-height: 100vh
    display: flex
    flex-direction: column
    justify-content: space-between

    .page-main
        flex-grow: 1


p
    margin: 0 0 30px 0

ul
    margin: 0

img
    width: 100%
    max-width: max-content
    height: auto

input
    &[type='text'], &[type='email'], &[type='number'], &[type='date'], &[type='password'], &[type='tel'], &[type='submit']
        -webkit-appearance: none
        height: 60px
        width: 100%
        padding: 0 20px
        border-radius: 8px
        border: 2px solid var(--color-base-60)
        background-color: var(--color-bg)
        font-size: 20px
        color: var(--color-white)

    &[type='text'][readonly], &[type='email'][readonly], &[type='number'][readonly], &[type='date'][readonly], &[type='tel'][readonly]
        opacity: .5

    &[type='checkbox']
        -webkit-appearance: none
        display: none

        ~ label::before
            -webkit-appearance: none
            content: ''
            display: inline-block
            position: absolute
            left: 0
            top: 50%
            transform: translateY(-50%)
            width: 20px
            height: 20px
            background-color: var(--color-base)
            border-radius: 3px

        &:checked ~ label::after
            content: ''
            display: inline-block
            position: absolute
            left: 2px
            top: 50%
            transform: translateY(calc(-50% - 4px))
            width: 23px
            height: 23px
            background-image: url('/assets/images/check-white.svg')
            background-size: contain
            background-repeat: no-repeat

        ~ label
            cursor: pointer

    &[type='radio'] ~ label
        cursor: pointer

select
    -webkit-appearance: none
    width: 100%
    background-color: var(--color-bg)
    border-radius: 8px
    border: 2px solid var(--color-base-60)
    height: 60px
    color: var(--color-base)
    font-size: calc(18px + 2 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 700
    text-transform: uppercase
    padding: 0 20px
    appearance: none
    cursor: pointer

.container
    width: 100%
    max-width: var(--container)
    margin: 0 auto
    padding: 0 50px

.section
    padding: var(--offset) 0

.button
    -webkit-appearance: none
    display: grid
    height: var(--button-height)
    padding: 0 35px
    align-items: center
    justify-content: center
    font-size: 25px
    background-color: var(--color-base)
    color: var(--color-black)
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase
    border: solid 2px var(--color-base)
    border-radius: 8px
    cursor: pointer
    transition: 0.3s

.button_sm
    font-size: 20px
    height: var(--button-sm-height)

.button_center
    margin: 0 auto

.button:hover
    background-color: var(--color-white)
    color: var(--color-base)

.button:hover > a
    color: var(--color-base)

.title
    position: relative
    width: max-content
    margin: 0 auto

.title__bg
    font-family: Inter
    font-size: calc(35px + 45 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 900
    line-height: normal
    letter-spacing: 4.4px
    text-transform: uppercase
    color: var(--color-light)
    opacity: 0.6
    //-webkit-text-stroke-width: 2px
    //-webkit-text-stroke-color: var(--color-base)
    text-shadow: -2px -2px 0 var(--color-base), 2px -2px 0 var(--color-base), -2px 2px 0 var(--color-base), 2px 2px 0 var(--color-base)

.title__bg_black
    color: var(--color-black)

.title__content
    position: absolute
    width: 100%
    margin: 0
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    font-family: Inter
    text-align: center
    color: var(--color-black)
    font-size: calc(20px + 20 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 900
    line-height: normal
    text-transform: uppercase

h1, .h1
    margin: 0

h2, .h2
    margin: 0 0 25px 0
    color: var(--color-base)
    font-size: 40px
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase

h3, .h3
    margin: 0 0 18px 0
    color: var(--color-base)
    font-size: 28px
    font-style: normal
    font-weight: 700
    line-height: normal

.drop__item
    position: absolute
    opacity: 0
    visibility: hidden
    height: 0
    display: grid
    grid-template-rows: repeat(2, max-content)
    gap: 15px
    transition: 0.3s

.drop__item--visible
    position: static
    opacity: 1
    visibility: visible
    height: auto

@media (min-width: 1921px)
    .title__content
        font-size: 40px

    .breadcrumb__item
        a, &:last-child a
            font-size: 20px

        &:after
            height: 16px

    select
        font-size: 20px

@media (max-width: 680px)
    \:root
        --offset: 50px
        --button-height: 75px

    /*--button-sm-height: 75px;

    .container
        padding: 0 20px

    .title__bg
        letter-spacing: 2px

    select
        font-weight: normal

@media (max-width: 374px)
    .step__title .title__bg
        font-size: 28px



//.page-wrapper
//    min-height: 100vh
//    display: flex
//    flex-direction: column
//    justify-content: space-between
//
//    .page-main
//        flex-grow: 1

//body
//    min-height: 100vh
//    font-family: 'Montserrat', sans-serif
//
//.button
//    display: inline-flex
//    width: max-content
//    height: 80px
//    padding: 0 56px
//    align-items: center
//    justify-content: center
//    font-size: calc(20px + 4 * ((100vw - 375px) / (1920 - 375)))
//    text-transform: uppercase
//    font-style: normal
//    font-weight: 700
//    line-height: normal
//    background-color: #F79817
//    border-radius: 10px
//    color: #000000
//    transition: .3s
//
//    &:hover
//        color: white
//
//.uk-background-secondary
//    background-color: black
//
//.uk-lightbox
//    background-color: rgba(#000, 0.85)
//
//.uk-button
//    height: 50px
//    line-height: 50px
//    box-sizing: border-box
//
//.uk-button-default
//    color: #ff5e1d
//    font-size: 14px
//    font-weight: 700
//    text-transform: uppercase
//
//    background-color: white
//    border: 2px solid white
//    +tr(0.3s)
//    +hh
//        background-color: $accent
//        border-color: $accent
//        color: #ffffff
//
//.uk-button-danger
//    background-color: $accent
//    border-color: $accent
//    color: #ffffff
//    +tr(0.3s)
//    +hh
//        color: #ff5e1d
//        background-color: white
//        border: 2px solid white
//
//
//.uk-section-large
//    padding-top: 120px
//    padding-bottom: 120px
//    +r(959)
//        padding-top: 70px
//        padding-bottom: 70px
//    +r(639)
//        padding-top: 40px
//        padding-bottom: 40px
//
//.uk-accordion
//
//    .uk-accordion-title
//        display: flex
//        align-items: center
//        background-color: white
//        color: black
//        font-size: 22px
//        +fz(22, 12)
//        line-height: 1
//        font-weight: 600
//        text-transform: uppercase
//        padding: 10px
//        +tr(0.3s)
//
//        &:before
//            margin-left: 0
//            margin-right: 20px
//            background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' data-svg='triangle-down'%3E%3Cpolygon points='5 7 15 7 10 12' fill='%23ff5e1d'%3E%3C/polygon%3E%3C/svg%3E")
//            +r(959)
//                margin-right: 10px
//
//    .uk-open>
//        .uk-accordion-title
//            background-color: $accent
//    &:before
//        background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' data-svg='triangle-down'%3E%3Cpolygon points='5 7 15 7 10 12' fill='%23000'%3E%3C/polygon%3E%3C/svg%3E")
//
//    .uk-accordion-content
//        padding: 0 60px
//        +r(959)
//            padding: 0
//
//.uk-accordion>:nth-child(n+2)
//    +r(639)
//        margin-top: 10px
//
//.uk-background-secondary
//    .uk-accordion-content
//        color: #ffffff
//
//.uk-panel-scrollable
//    border: 0
//    resize: none
//    max-height: 120px
//    padding-top: 0
//
//    &::-webkit-scrollbar
//        width: 5px
//
//    &::-webkit-scrollbar-thumb
//        background-color: $accent
//        outline: 1px solid slategrey
//
//.uk-dotnav>*>*
//    border: 1px solid rgba(#000000, 0.7)
//.uk-dotnav>.uk-active>*
//    background-color: $accent
//    border: 0
//
//.uk-background-secondary
//    .uk-dotnav>*>*
//        border: 1px solid #ffffff
//    .uk-dotnav>.uk-active>*
//        border: 0
//
//.uk-slideshow
//    position: relative
//
//.nashiraboti_prev
//    position: absolute
//    left: 0
//    background-color: #ff5e1d
//    z-index: 999999
//    border-bottom-right-radius: 15px
//    padding-right: 10px
//
//.nashiraboti_next
//    position: absolute
//    right: 0
//    background-color: #ff5e1d
//    z-index: 999999
//    border-bottom-left-radius: 15px
//    padding-left: 10px