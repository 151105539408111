/*Vacancy2-top

.vacancy2_top
    padding: var(--offset) 0 80px 0
    //padding: calc(var(--offset) + var(--header-height)) 0 80px 0 /*Нужен без крошек*/
    background-color: var(--color-light)

    p
        margin: 0

    h2
        margin: 0

.vacancy2__title
    text-align: center
    width: 100%

.vacancy2_top__wrapper
    display: grid
    grid-auto-flow: row
    gap: 80px

#opis_txt_vak_vacansy_new
    display: grid
    grid-auto-flow: row
    gap: 40px


.vacansy_new_block_opis
    display: grid
    grid-auto-flow: row
    gap: 40px

.vacancy2_top
    .title__bg
        //font-family: Inter
        letter-spacing: unset

    //.title__content
    //    font-family: Inter

//.vacancy2_top__item
//    display: grid
//    grid-auto-flow: row
//    gap: 40px

//.vacancy2_top__header
//    color: var(--color-black-90)
//    font-size: 25px
//    font-style: normal
//    font-weight: 500
//    line-height: normal

//.vacancy2_top__block
//    color: var(--color-black)
//    font-size: 25px
//    font-style: normal
//    font-weight: 500
//    line-height: normal

.vacancy2_top p
    color: var(--color-black)
    font-size: calc(15px + 10 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 500
    line-height: normal

//.vacancy2_top__title
//    color: var(--color-base)
//    font-size: 40px
//    font-style: normal
//    font-weight: 700
//    line-height: normal
//    text-transform: uppercase

#opis_txt_vak_vacansy_new span
    color: var(--color-base)
    font-size: calc(20px + 20 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase
    text-decoration: none

.adress_vacancy
    color: var(--color-black)
    font-size: calc(15px + 10 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 500
    line-height: normal


.adress_vacancy b
    color: var(--color-base)
    font-size: calc(20px + 20 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase


.vacancy2_top ul
    list-style: none
    display: grid
    grid-auto-flow: row
    gap: 10px
    padding: 0

    li
        display: grid
        grid-auto-flow: column
        justify-content: start
        gap: 20px
        align-items: center
        color: var(--color-black)
        font-size: calc(15px + 10 * ((100vw - 375px) / (1920 - 375)))
        font-style: normal
        font-weight: 500

        &::before
            content: ""
            background-color: var(--color-base)
            display: inline-block
            width: 15px
            height: 15px
            border-radius: 50%

@media(max-width: 1000px)
    .vacancy2__title
        max-width: calc(100vw - 40px)
        text-align: center

    .vacancy2_top__wrapper
        gap: 60px

    .vacansy_new_block_opis
        gap: 30px

@media(max-width: 700px)
    .vacancy2_top__wrapper
        gap: 50px

    .vacansy_new_block_opis
        gap: 0

    #opis_txt_vak_vacansy_new
        gap: 30px

@media(max-width: 480px)
    .vacancy2_top ul > li
        &::before
            width: 10px
            height: 10px