.price
  background-color: var(--color-light)

.price__title
  margin-bottom: 70px

.price-accordion
  display: grid
  gap: 15px
  margin-bottom: 15px

.price-accordion__item
  display: grid
  grid-template-columns: 1fr
  justify-content: start
  align-items: center
  gap: 15px
  padding: 22px 35px
  border: 2px solid var(--color-base)
  background-color: var(--color-white)
  border-radius: 20px
  overflow: hidden
  cursor: pointer

.price-accordion__title
  display: grid
  grid-auto-flow: column
  justify-content: start
  align-items: center
  gap: 56px
  font-family: 'Inter'
  font-size: calc(20px + 5 * ((100vw - 375px) / (1920 - 375)))
  font-weight: 600
  line-height: normal
  text-transform: uppercase
  color: var(--color-black)

.price-accordion__data
  padding: 0 68px 5px 68px
  color: var(--color-black)
  font-size: calc(18px + 7 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 400
  line-height: normal
  //text-decoration-line: underline

.price-accordion__title::before
  content: ""
  width: 0
  height: 0
  border-left: 6px solid transparent
  border-right: 6px solid transparent
  border-top: 10px solid var(--color-base)
  transition: 0.1s

.price-table
  display: grid
  gap: 17px

.price-table__row
  display: grid
  gap: 5px
  position: relative
  grid-template-columns: max-content 1fr
  line-height: 1

.price-table__label
  //text-decoration: underline

.price-table__value
  display: grid
  grid-template-columns: 1fr minmax(170px, min-content)
  gap: 5px
  align-items: end
  text-align: right

  &::before
    content: ""
    border-bottom: 1px dashed var(--color-black)

.button-collection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 20px

.button-collection__item
  border-radius: 20px
  font-size: calc(18px + 2 * ((100vw - 375px) / (1920 - 375)))

/*Price-offset

.price_offset
  background-color: var(--color-light)
  padding: var(--offset) 0 80px 0
  //padding: calc(var(--offset) + var(--header-height)) 0 60px 0 /*Нужен без крошек*/

.price__subtitle
  color: var(--color-black)
  text-align: center
  font-size: calc(20px + 5 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 500
  line-height: normal
  text-transform: uppercase
  margin-bottom: 50px

.price-title .price__title
  margin-bottom: 50px

.price_offset .price-table__row
  display: grid
  gap: 5px
  position: relative
  grid-template-columns: minmax(min-content, max-content) 1fr

.price_offset .price-table__value
  grid-template-columns: 1fr minmax(140px, min-content)

@media (min-width: 1921px)
  .price-accordion__title, .price-accordion__data, .price__subtitle
    font-size: 25px

  .button-collection__item
    font-size: 20px

@media (max-width: 1000px)
  .price__title
    margin-bottom: 50px

  .price-accordion__title
    gap: 25px

  .price-table__value
    grid-template-columns: 1fr minmax(100px, min-content)

  .price-accordion__data
    padding: 0 25px 10px 37px

@media (max-width: 900px)
  //.price-accordion__data
  //  padding: 0 25px 0 37px

@media (max-width: 800px)
  .button-collection
    grid-template-columns: 1fr 1fr

  .button-collection__item
    font-weight: 900

    &:last-child
      grid-column: 1 / -1

  .price-table__row
    grid-template-columns: 2fr 1fr

  .price-table__value:before
    border-bottom: unset

@media (max-width: 640px)
  .price-accordion__data
    text-decoration-line: none

  .price-table
    gap: 25px

  .price-table__row
    grid-template-columns: 1fr

  .price-table__value
    grid-template-columns: 1fr
    text-align: left

    &::before
      display: none

  .price_offset .price-table__row
    grid-template-columns: 1fr

  .price_offset .price-table__value
    grid-template-columns: 1fr
    text-align: left

@media (max-width: 565px)
  .price-accordion__item
    padding: 25px

  .button-collection
    grid-template-columns: 1fr


//.price_list
//  //min-height: 100vh
//  //width: 100%
//  background-color: rgba(247, 152, 23, .1)
//
//  &__title
//    font-size: 60px
//    font-weight: 900
//    text-align: center
//    color: transparent
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 30px
//    padding-bottom: 10px
//
//    &__title_copy
//      font-size: 28px
//      font-weight: 900
//      text-align: center
//      color: black
//      -webkit-text-stroke-width: 0px
//      -webkit-text-stroke-color: transparent
//      position: relative
//      top: -55px
//
//  &__body
//    //min-height: 80vh
//    padding: 20px 0
//    position: relative
//    display: flex
//    flex-direction: column
//
//    &__item
//
//      &__title
//        border: 1px solid #F79817
//        border-radius: 10px
//        padding: 12px 10px 12px 15px
//        margin-bottom: 10px
//        font-size: 14px
//        font-weight: 600
//        background-color: white
//        line-height: 14px
//        cursor: pointer
//        text-transform: uppercase
//
//        &:hover
//          background-color: #F79817
//          color: white
//
//        &:before
//          content: url("/img-ph/price_trian.png")
//          margin-right: 20px
//
//      &__list
//        display: none
//        //display: flex
//        flex-direction: column
//        padding: 0 20px 20px 70px
//
//        &#{&} a
//          color: black
//          text-decoration: underline
//          text-decoration-color: #F79817
//
//          &:hover
//            color: #F79817
//            text-decoration-color: black
//
//        &__item
//          display: flex
//          flex-direction: row
//          justify-content: space-between
//
//
//          &__title
//            padding: 5px 10px 5px 10px
//            font-size: 16px
//            font-weight: 600
//
//          &__price
//            padding: 5px 10px 5px 10px
//            font-size: 16px
//            font-weight: 600
//
//
//
//  &__callback
//    display: flex
//    flex-direction: row
//    justify-content: space-between
//    padding-bottom: 50px
//
//    &__item
//      width: calc(90% / 3)
//      padding: 20px 10px
//      background-color: #F79817
//      color: black
//      border-radius: 15px
//      font-size: 14px
//      font-weight: 600
//      text-align: center
//
////.list_item_hide
////  display: none
////  //max-height: 0
////  //overflow: hidden
////  padding: 0
////  //visibility: hidden
////  //display: none
////  //-webkit-transition: all 1s !important
////  //-moz-transition: all 1s !important
////  //-ms-transition: all 1s !important
////  //-o-transition: all 1s !important
////  //transition: all 1s
//
