footer
  padding: 48px 0 34px 0
  background-color: var(--color-bg)

.footer__wrap
  display: grid
  grid-template-columns: var(--one-third) var(--one-four) 1fr
  margin-bottom: 80px
  gap: 20px

.footer__widget
  color: var(--color-white)

.footer__title
  margin-bottom: 20px
  font-size: calc(16px + 4 * ((100vw - 375px) / (1920 - 375)))
  font-weight: 700
  text-transform: uppercase
  color: var(--color-white)

.footer__menu
  display: grid
  gap: 15px
  padding: 5px 0
  list-style-type: none

.footer__menu-link
  color: var(--color-base)
  font-size: calc(16px + 4 * ((100vw - 375px) / (1920 - 375)))
  font-weight: 500
  text-transform: uppercase
  text-decoration: none

.footer__logo
  margin-bottom: 50px

.footer__location
  display: grid
  gap: 10px

.footer__location-item
  font-size: 20px
  color: var(--color-white)
  font-weight: 600

.footer__common
  display: grid
  grid-template-rows: repeat(2, max-content) 1fr
  align-items: end

.footer__legal
  color: var(--color-base)
  font-size: 20px
  font-weight: 500
  text-transform: uppercase
  text-decoration: none

.footer__copyright
  font-family: Inter
  font-size: calc(12px + 6 * ((100vw - 375px) / (1920 - 375)))
  font-weight: 400
  color: var(--color-white-80)

@media (min-width: 1921px)
  .footer__title, .footer__menu-link
    font-size: 20px

  .footer__copyright
    font-size: 18px

@media (max-width: 1120px)
  .footer__wrap
    grid-template-columns: repeat(2, max-content)
    gap: 30px 50px
    margin-bottom: 30px

  .footer__common
    grid-column: 1 / -1

  .footer__location
    margin-bottom: 43px

@media (max-width: 1120px)
  .footer__wrap
    column-gap: 20px

@media (max-width: 565px)
  .footer__wrap
    grid-template-columns: max-content 1fr

  .footer__logo
    margin: 0 auto 50px auto

  .footer__location
    text-align: center

@media (max-width: 380px)
  .footer__wrap
    grid-template-columns: 1fr

  .footer__location-item
    font-size: 18px



//.footer
//  background-color: black
//  font-size: 16px
//  font-weight: 500
//
//  &__body
//    display: flex
//    flex-direction: row
//    justify-content: space-between
//    flex-wrap: wrap
//    padding: 30px 0 30px 0
//
//    &__item
//      display: flex
//      flex-direction: column
//      justify-content: space-between
//
//      &__title
//        color: white
//        text-transform: uppercase
//        margin-bottom: 15px
//
//      &__menu
//        color: #F79817
//        text-transform: uppercase
//        margin-bottom: 10px
//
//        &:hover
//          color: white
//
//      &__logo
//        text-transform: uppercase
//        color: white
//        font-weight: 800
//
//        &__orange
//          background-color: #F79817
//          border-radius: 5px
//          color: black
//          padding: 5px 3px 5px 3px
//          margin-left: -3px
//          font-weight: 800
//
//      &__address
//        color: white
//        line-height: 25px
//
//    &__description
//      flex-basis: 100%
//      color: white
//      font-size: 12px
//      font-weight: 300
//      margin-top: 30px
