.reviews
  position: relative
  background-color: var(--color-bg)

.reviews_invert
  position: relative
  background-color: var(--color-light)

.swiper-review
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 20px

.reviews__title
  margin-bottom: 80px

.reviews-swiper__item
  display: grid
  grid-template-rows: max-content minmax(1fr, max-content)
  align-items: center
  align-content: start
  gap: 15px
  padding: 20px
  border-radius: 8px
  border: 2px solid var(--color-base)
  background: var(--color-gray)
  height: auto

.reviews__item__title
  color: var(--color-base)
  font-size: 32px
  font-style: normal
  font-weight: 700
  line-height: normal
  text-align: center
  min-height: 78px

.reviews__item__name
  color: var(--color-white-80)
  text-align: center
  font-size: 25px
  font-style: normal
  font-weight: 400
  line-height: normal

.reviews__item__description
  color: var(--color-white-80)
  text-align: center
  font-size: 22px
  font-style: normal
  font-weight: 500
  line-height: normal

.reviews-swiper__prev, .reviews-swiper__next
  width: 100px
  height: 100px
  border-radius: 50%
  background-color: var(--color-gray)
  border: solid 2px var(--color-base)
  color: var(--color-base, var(--color-base))

.reviews-swiper__prev
  left: 64px

.reviews-swiper__next
  right: 64px

.reviews_invert
  .reviews-swiper__item
    display: grid
    grid-template-rows: max-content minmax(1fr, max-content)
    align-items: center
    align-content: start
    gap: 15px
    padding: 20px
    border-radius: 8px
    border: 2px solid var(--color-base)
    background-color: var(--color-white)
    height: auto

  .reviews__item__name, .reviews__item__description
    color: var(--color-black-80)

  .reviews-swiper__next, .reviews-swiper__prev
    background-color: unset

@media (max-width: 1550px)
  .reviews-swiper__next, .reviews-swiper__prev
    opacity: 0.7
    width: 75px
    height: 75px


@media(max-width: 1100px)
  .reviews__title
    margin-bottom: 50px

@media (max-width: 1000px)
  .reviews__item__description
    font-size: 20px



@media(max-width: 750px)
  .swiper-review
    grid-template-columns: 1fr

  .reviews-swiper__item
    width: calc(100vw - 375px)

  .reviews-swiper__next, .reviews-swiper__prev
    display: none

@media(max-width: 700px)


//.reviews
//  width: 100%
//  background-color: black
//
//  &__title
//    font-size: 65px
//    background-color: black
//    font-weight: 900
//    text-align: center
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 50px
//    padding-bottom: 30px
//    text-transform: uppercase
//
//  &__body
//    display: flex
//    flex-direction: row
//    justify-content: space-between
//    flex-wrap: wrap
//    padding-bottom: 30px
//
//    &__item
//      //width: 50%
//      height: 365px
//      flex-basis: 30%
//      margin-bottom: 15px
//      text-align: center
//      padding: 10px
//      border: 1px solid #F79817
//      border-radius: 5px
//      display: flex
//      flex-direction: column
//      justify-content: space-between
//
//      &__image
//        width: 60%
//        height: auto
//        flex-basis: 20%
//        margin-left: auto
//        margin-right: auto
//        background-size: cover
//
//      &__title
//        color: #F79817
//        font-size: 30px
//        font-weight: 700
//        flex-basis: 10%
//
//      &__description
//        color: white
//        font-size: 20px
//        font-weight: 500
//        margin: 10px 0
//        flex-basis: 70%
//
//
//
