/*Breadcrumbs

.breadcrumbs
    //background-color: var(--color-bg)
    padding-top: calc(40px + var(--header-height))
    padding-bottom: 40px

.breadcrumbs_black
    background-color: var(--color-bg)

.breadcrumbs ol
    padding: 0
    margin: 0
    list-style: none

    display: grid
    grid-template-columns: repeat(4, max-content)
    align-items: center
    height: 30px
    //gap: 10px

/*gap: 10px;

.breadcrumb__item
    display: grid
    grid-template-columns: repeat(2, max-content)
    align-items: center
    gap: 10px
    width: max-content

    &:after
        height: calc(8px + 8 * ((100vw - 375px) / (1920 - 375)))
        width: 16px
        content: ''
        display: inline-block
        background-image: url('/assets/images/breadcrumbs.svg')
        background-repeat: no-repeat
        background-position: center

    a
        text-decoration: none
        color: var(--color-white)
        font-size: calc(10px + 7 * ((100vw - 375px) / (1920 - 375)))
        font-style: normal
        font-weight: 600
        line-height: normal
        text-transform: uppercase

        &:hover
            color: var(--color-base)
            text-decoration: underline
            transition: width 0.3s ease-out

    &:last-child
        a
            cursor: default
            pointer-events: none
            color: var(--color-base)
            font-size: calc(10px + 7 * ((100vw - 375px) / (1920 - 375)))
            font-style: normal
            font-weight: 600
            line-height: normal
            text-transform: uppercase

        &:after
            display: none

/*Адаптив breadcrumbs

@media (max-width: 600px)
    /*.breadcrumbs ol {
    /*    height: 60px;
    /*    grid-template-columns: repeat(2, max-content);
    /*}

    .breadcrumb__item:after
        height: 10px

/*--Адаптив breadcrumbs--