.error
    padding: calc(var(--offset) + var(--header-height)) 0 80px 0
    background: var(--color-light)

.error h1
    font-size: 25px
    font-weight: 600

.error p
    font-size: 20px
    font-weight: 400

.error a
    color: var(--color-base)
    text-decoration: none
    font-size: 20px
    font-weight: 600
