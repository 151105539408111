.banner
    padding: calc(var(--offset) + var(--header-height)) 0 60px 0
    background-color: var(--color-light)

.banner__item
    display: grid
    position: relative
    height: 100%
    padding: var(--offset) 32px
    aspect-ratio: 1216 / 760
    box-sizing: border-box
    background-color: var(--color-bg)
    border-radius: 20px
    overflow: hidden

.banner__info
    display: grid
    position: relative
    grid-template-rows: repeat(3, max-content) 1fr
    gap: 10px
    height: 100%
    align-items: start
    color: var(--color-white)
    z-index: 1

.banner__image
    position: absolute
    //width: calc(200px + 329 * ((100vw - 375px) / (1920 - 375)))
    //height: calc(200px + 400 * ((100vw - 375px) / (1920 - 375)))
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover
    z-index: 0

.banner__logo
    display: grid
    grid-auto-flow: column
    justify-content: start
    align-items: center
    gap: 8px
    font-size: calc(26px + 34 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 900
    line-height: normal
    text-transform: uppercase

.banner__logo-ident
    padding: 12px 8px
    background-color: var(--color-base)
    color: var(--color-black)
    border-radius: 10px

.banner__title
    margin: 0 0 20px 0
    font-size: calc(20px + 10 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase

.banner__descriptor
    display: grid
    gap: 20px
    margin-bottom: 40px

.banner__descriptor__item
    color: var(--color-base)
    font-size: calc(18px + 7 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase

.banner__button
    justify-self: start
    font-size: calc(20px + 5 * ((100vw - 375px) / (1920 - 375)))

/*Offer

.banner_offer
    padding: 40px 0 130px
    background-color: var(--color-gray)

    .banner__item
        grid-template-columns: repeat(2, 1fr)
        justify-content: space-between
        padding: 0
        min-height: fit-content
        background-color: unset
        border-radius: 0
        aspect-ratio: unset
        gap: 30px

.banner__header
    color: var(--color-base)
    font-size: calc(24px + 24 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase

.banner_offer
    .banner__info
        padding: 15px 0
        grid-template-rows: repeat(4, max-content)
        gap: 30px

    .banner__image
        position: unset

    .banner__descriptor
        margin-bottom: unset

    .banner__title
        margin: 0

/*Banner-merc

//.banner__header_white
//    color: var(--color-white)
//    font-size: calc(30px + 20 * ((100vw - 375px) / (1920 - 375)))
//    font-style: normal
//    font-weight: 700
//    line-height: normal
//    text-transform: uppercase

.banner__header_highlight
    color: var(--color-base)
    font-size: calc(30px + 20 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase

/*Banner-service

.banner_subservice
    padding: 80px 0
    background-color: var(--color-bg)

    .banner__item
        grid-template-columns: repeat(2, max-content)
        justify-content: space-between
        padding: 0
        min-height: unset
        background-color: unset
        border-radius: 0

    .banner__info
        padding: 0
        grid-template-rows: repeat(2, max-content)
        gap: 50px

    .banner__image
        position: unset

    img
        border: 2px solid var(--color-base)
        border-radius: 8px

    .banner__descriptor
        margin-bottom: unset

    .banner__title
        margin: 0


/* Фиксируем шрифты для больших экранов
@media (min-width: 1921px)
    .banner__logo
        font-size: 60px

    .banner__header, .banner__header_white, .banner__header_highlight
        font-size: 50px

    .banner__title
        font-size: 30px

    .banner__descriptor__item, .banner__button
        font-size: 25px

@media (max-width: 940px)
    .banner
        .banner__item
            aspect-ratio: unset
            height: max-content

        .banner__info
            max-width: 68%

@media (max-width: 565px)
    .banner
        background-color: var(--color-bg)

        .banner__item
            //height: calc(100vh - (60px + var(--header-height)))
            padding: 0
            border-radius: unset

        .banner__info
            grid-template-rows: repeat(2, max-content) 1fr
            max-width: unset

        .banner__logo
            display: none

        .banner__title
            margin-bottom: 40px

        .banner__descriptor
            gap: 15px

    .banner__action
        position: relative
        align-self: center

    .banner
        .banner__item img
            position: static
            width: 100%
            height: 100%
            object-fit: cover

        .banner__button
            height: 60px
            padding: 0 12px
            position: absolute
            top: 50%
            left: 0
            transform: translateY(-50%)

/*Banner_offer

@media (max-width: 1170px)
    .banner_offer
        padding: 40px 0

        .banner__info
            max-width: 95%
            padding: 40px 0
            grid-template-rows: repeat(4, max-content)
            gap: 40px

        .banner__item
            grid-template-columns: repeat(2, 1fr)
            justify-content: space-between
            padding: 0
            max-height: 760px

@media (max-width: 1090px)
    .banner_offer .banner__info
        /*max-width: 90%;
        padding: 30px 0
        grid-template-rows: repeat(4, max-content)
        gap: 30px

@media (max-width: 1030px)
    .banner_offer .banner__info
        /*max-width: 100%;
        padding: 20px 0
        grid-template-rows: repeat(4, max-content)
        gap: 20px

@media (max-width: 970px)
    .banner_offer .banner__item
        grid-template-columns: repeat(2, 1fr)

@media (max-width: 930px)
    .banner_offer
        padding: 0 0 40px

        .banner_item
            /*margin-bottom: 40px;
            overflow: unset

        //.banner__button
        //    margin-top: 60px
        //    margin-bottom: 30px
        //    height: 60px
        //    padding: 0 12px
        //    position: relative
        //    top: 50%
        //    left: 100%
        //    transform: translate(-50%, -50%)

        /*justify-self: unset;
        /*transform: translateY(-50%);

        .banner__info
            grid-template-rows: repeat(3, max-content)
            gap: 10px
            padding: 0 0 20px

        .banner__descriptor
            gap: 10px

//@media (max-width: 800px)
//    .banner_offer .banner__button
//        margin-top: 40px
//
//@media (max-width: 750px)
//    .banner_offer .banner__button
//        margin-top: 30px

@media (max-width: 840px)
    .banner_offer
        .banner__item
            grid-template-columns: 1fr
            padding: 0 20px

            .banner__image
                position: relative
                width: 50%
                height: 100%
                object-fit: cover
                left: 50%

        .banner__button
            //height: 60px
            //padding: 0 12px
            position: absolute
            top: 50%
            left: 0
            transform: translateY(-50%)
            margin: 0

    .banner_offer .banner__action
        position: relative
        align-self: center

@media(max-width: 560px)
    .banner_offer .banner__button
        height: 60px
        padding: 0 12px

@media (max-width: 470px)
    .banner_offer
        .banner__image
            display: none

        .banner__button
            position: relative
            margin-top: 30px
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)



/*Banner_subservice*/

@media(max-width: 1200px)
    .banner_subservice
        .banner__item
            aspect-ratio: unset
            grid-template-columns: repeat(2, 1fr)
            justify-content: unset
            gap: 20px

@media(max-width: 840px)
    .banner_subservice .banner__info
        grid-template-rows: max-content 1fr
        gap: 30px


@media(max-width: 770px)
    .banner_subservice
        .banner__action
            display: grid
            grid-template-columns: repeat(2, 1fr)

    .banner_subservice
        .banner__item
            grid-template-columns: 1fr

    .banner_subservice
        #col_btn
            display: block
            position: relative
            top: 50%
            left: 0
            transform: translateY(-50%)


@media(max-width: 680px)
    .banner_subservice
        padding: 30px 0

    .banner_subservice #col_btn
        height: 60px
        padding: 0 12px

@media(max-width: 470px)
    .banner_subservice
        .banner__image
            display: none

    .banner_subservice .banner__action
        grid-template-columns: 1fr

    .banner_subservice
        #col_btn
            left: 50%
            transform: translate(-50%, -50%)