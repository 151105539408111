.step
    background-color: var(--color-light)

.step__title
    margin-bottom: 80px

.step__list
    display: grid
    max-width: 1118px
    margin: 0 auto
    gap: 35px

.step__item
    position: relative
    display: grid
    grid-template-columns: 113px 1fr
    gap: 56px
    align-items: center
    counter-increment: item
    list-style-type: none
    color: var(--color-black-80)
    font-family: Montserrat
    font-size: calc(18px + 12 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 500
    line-height: normal

    &::before
        content: counter(decimal-leading-zero) counter(item) "."
        color: var(--color-base)
        font-size: calc(30px + 50 * ((100vw - 375px) / (1920 - 375)))
        font-style: normal
        font-weight: 900
        line-height: normal

@media (min-width: 1921px)
    .step__item
        font-size: 30px

        &::before
            font-size: 80px

@media (max-width: 1000px)
    .step__title
        margin-bottom: 50px

    .step__list
        padding: 0

    .step__item
        grid-template-columns: minmax(max-content, 60px) 1fr
        gap: 20px

        &::before
            align-self: start

@media (max-width: 430px)
    .step__item
        grid-template-columns: minmax(max-content, 50px) 1fr

@media (max-width: 375px)
    .step__item
        grid-template-columns: minmax(max-content, 43px) 1fr
