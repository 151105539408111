.video-reviews
    background-color: var(--color-bg)

.video-reviews__wrapper
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 20px

.video-reviews__title
    margin-bottom: 80px

.video-reviews__item
    //display: grid
    //grid-template-rows: repeat(4, max-content)
    align-items: center
    //gap: 10px
    padding: 10px
    border-radius: 8px
    border: 2px solid var(--color-base)
    background: var(--color-gray)

.video-reviews__item__image img
    border-radius: 8px
    display: block
    margin-bottom: 21px

.video-reviews__item__title
    color: var(--color-base)
    font-size: 32px
    font-style: normal
    font-weight: 700
    line-height: normal
    text-align: center

.video-reviews__item__name
    color: var(--color-white)
    text-align: center
    font-size: 25px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin-bottom: 10px

.video-reviews__item__description
    color: var(--color-white)
    text-align: center
    font-size: 25px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin-bottom: 11px

@media (max-width: 1160px)
    .video-reviews__wrapper
        grid-template-columns: repeat(3, 1fr)

@media (max-width: 1000px)
    .video-reviews__wrapper
        grid-template-columns: repeat(2, 1fr)

    .video-reviews__title
        margin-bottom: 50px

    .video-reviews__item:last-child
        grid-column: 1 / -1
        margin: 0 auto
        max-width: 408px

@media (max-width: 565px)
    .video-reviews__wrapper
        grid-template-columns: minmax(min-content, 335px)
        justify-content: center

@media (max-width: 430px)
    .video-reviews__wrapper
        grid-template-columns: 1fr

@media (max-width: 374px)
    .video-reviews__title .title__bg_black
        font-size: 28px



//.video_reviews
//  width: 100%
//  background-color: black
//
//  &__title
//    font-size: 65px
//    background-color: black
//    font-weight: 900
//    text-align: center
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 50px
//    padding-bottom: 30px
//    text-transform: uppercase
//
//  &__body
//    //display: flex
//    //flex-direction: row
//    //justify-content: space-between
//    //flex-wrap: wrap
//    padding-bottom: 30px
//    width: 100%
//
//    &__item
//      width: calc((100%) / 3) !important
//      //flex-basis: calc(100% / 3) - 380px
//      //margin: 0 10px 0 10px
//      margin-bottom: 15px
//      text-align: center
//      padding: 10px
//      border: 1px solid #F79817
//      border-radius: 5px
//      box-sizing: border-box !important
//
//
//      &__title
//        color: #F79817
//        font-size: 30px
//        font-weight: 700
//        margin: 20px 0 10px 0
//
//      &__bio
//        display: block
//        color: white
//        font-size: 20px
//        font-weight: 500
//        margin: 10px 0
//
//      &__description
//        display: block
//        color: white
//        font-size: 20px
//        font-weight: 500
//        margin: 10px 0
//
//.swiperVideoReviews .swiper-wrapper
//  box-sizing: border-box !important
//  //display: flex
//  //flex-direction: row
//  //justify-content: space-between !important
//
//
//.swiperVideoReviews .swiper-slide
//  box-sizing: border-box !important
//  height: auto
//  margin: auto
//  //width: 100%
