.popup
    display: none
    opacity: 0
    z-index: -100
    //display: grid
    width: 100%
    max-width: 598px
    padding: 40px 33px
    position: fixed
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    align-items: center
    justify-content: center
    background-color: var(--color-bg)
    border-radius: 8px

.popup--active
    display: grid
    opacity: 1
    z-index: 101

.popup_wrap
    display: grid
    gap: 18px

.popup__msg
    opacity: 0
    visibility: hidden
    z-index: -100
    position: absolute
    left: 0
    top: 0
    gap: 12px
    width: 100%
    height: 100%
    padding: 72px 24px 24px 24px
    border-radius: 8px
    background-color: var(--color-black)
    transition: 0.2s

.popup__msg__text
    font-size: calc(24px + 8 * ((100vw - 375px) / (1920 - 375)))
    color: var(--color-base)
    font-weight: 400
    width: 100%
    text-align: center

.popup-overlay
    opacity: 0
    position: fixed
    width: 100%
    height: 100%
    left: 0
    top: 0
    background: var(--color-white-70)
    z-index: -100

.popup-overlay--active
    opacity: 1
    z-index: 100

.popup__close
    width: 40px
    height: 40px
    position: absolute
    top: 16px
    right: 16px
    display: grid
    align-items: center
    cursor: pointer

.popup__close-line
    width: 1px
    height: 20px
    position: absolute
    left: 50%
    top: calc(50% - 10px)
    background-color: var(--color-base)

    &:first-child
        transform: rotate(45deg)

    &:last-child
        transform: rotate(-45deg)

.popup label
    display: inline-block
    color: var(--color-white-80)
    margin-bottom: 12px
    font-size: calc(18px + 2 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 700
    text-transform: uppercase

.popup__title
    font-size: calc(25px + 3 * ((100vw - 375px) / (1920 - 375)))
    margin-bottom: 30px

.form-contacts__item + .form-contacts__submit
    margin-top: 12px

input[type='submit']
    height: var(--button-sm-height)
    background-color: var(--color-base)
    color: var(--color-black)
    font-size: 25px

.form-contacts__approve
    position: relative

    label
        margin: 0
        padding: 0 0 0 35px
        font-size: calc(12px + 4 * ((100vw - 375px) / (1920 - 375)))
        font-weight: 500
        text-transform: uppercase

@media (min-width: 1921px)
    .popup__title
        font-size: 28px

    .popup label
        font-size: 20px

    .form-contacts__approve label
        font-size: 16px

@media (max-width: 680px)
    .popup
        width: 100%
        max-width: calc(100vw - 40px)

        label
            font-weight: normal
