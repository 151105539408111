.promotion
    position: relative
    background-color: var(--color-light)

.promotion__title
    margin-bottom: 80px

.swiper-promotion
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 20px

.promotions-swiper__item
    display: grid
    //grid-template-rows: max-content 90px max-content 1fr
    grid-template-rows: max-content 70px 0.5fr max-content
    align-items: start
    gap: 15px
    padding: 20px
    border-radius: 8px
    border: 2px solid var(--color-base)
    background: var(--color-white)
    height: auto

.promotion__item__image img
    max-width: unset
    border-radius: 8px
    display: block
    margin: 0 auto
    width: 100%
    height: 100%

.promotion__item__title
    color: var(--color-base)
    font-size: calc(23px + 2 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 600
    line-height: normal
    text-align: center

.promotion__item__description
    color: var(--color-black)
    font-size: 20px
    font-style: normal
    font-weight: 400
    line-height: normal


.promotion__item__price
    display: grid
    grid-template-columns: repeat(2, max-content)
    gap: 20px

    .current
        font-size: calc(16px + 8 * ((100vw - 375px) / (1920 - 375)))
        font-weight: 700

    .old
        font-size: calc(12px + 3 * ((100vw - 375px) / (1920 - 375)))
        font-weight: 500
        text-decoration: line-through
        align-self: center


.promotions-swiper__prev, .promotions-swiper__next
    width: 100px
    height: 100px
    border-radius: 50%
    background-color: unset
    border: solid 2px var(--color-base)
    color: var(--color-base, var(--color-base))

.promotions-swiper__prev
    left: 64px

.promotions-swiper__next
    right: 64px

//.promotion__item__ul
//    padding: 0
//    display: grid
//    grid-auto-flow: row
//    gap: 15px
//
//    li
//        display: grid
//        grid-auto-flow: column
//        justify-content: start
//        gap: 20px
//        align-items: start
//        color: var(--color-black)
//        font-size: 18px
//        font-style: normal
//        font-weight: 400
//
//        &::before
//            content: ""
//            background-color: var(--color-base)
//            display: inline-block
//            width: 10px
//            height: 10px
//            border-radius: 50%
//            align-self: start
//            margin-top: 0.6em
//            transform: translateY(-50%)

@media (min-width: 1921px)
    .promotion__item__title
        font-size: 25px

@media (max-width: 1550px)
    .promotions-swiper__next, .promotions-swiper__prev
        height: 75px
        width: 75px
    .promotion__item__image img
        max-width: 450px
        max-height: 450px


@media (max-width: 1100px)
    .promotion__item
        grid-template-rows: repeat(3, max-content) 1fr

@media (max-width: 1000px)
    .promotion__title
        margin-bottom: 50px

@media(max-width: 730px)
    .promotions-swiper__next, .promotions-swiper__prev
        display: none

