.footer_map
  display: block
  position: relative
  .map-box
    display: block
    position: relative
    z-index: 1
    iframe
      width: 100%
      height: 800px

  .map-content
    display: block
    position: absolute
    top: 70px
    left: 0
    width: 100%
    z-index: 2


.contacts-item
  display: block
  position: relative
  max-width: 327px
  margin: 0 auto
  background-color: black
  color: #ffffff
  overflow: hidden
  height: 100%
  &__title
    background-color: #ff5e1d
    padding: 30px 40px
    color: black
    font-size: 21px
    +fz(21, 16)
    font-weight: 700
  &__info
    padding: 30px 40px
    display: block
    position: relative
    overflow: hidden
    &:before
      content: ''
      display: block
      width: 200px
      height: 100px
      background-color: #aeaeae
      position: absolute
      right: 0
      bottom: 0
      transform: rotate(-25deg) translate(20px, 70px)
      z-index: 1

  &__adress
    color: white
    font-size: 16px
    +fz(16, 14)
    font-weight: 300
    line-height: 1.375
  &__phone
    a
      color: white
      font-size: 16px
      +fz(16, 14)
      font-weight: 300
      line-height: 1.375
      +tr(0.3s)
      +hh
        text-decoration: none
        color: $accent
  &__time-work
    color: white
    font-size: 16px
    +fz(16, 14)
    font-weight: 300
    line-height: 1.375
    margin: 20px 0
  &__location
    display: block
    position: relative
    z-index: 2
    a
      display: flex
      justify-content: space-between
      align-items: flex-end

      .text
        color: white
        font-size: 11px
        font-weight: 300
        line-height: 14px

      .uk-icon
        font-size: 30px
        flex-grow: 1
        display: flex
        justify-content: flex-end
        border-bottom: 1px dashed #ffffff
        margin-right: 30px
        color: #ffffff

        svg
          width: 32px
          height: 42px
          margin-right: -16px
          margin-bottom: -4px

      +hh
        text-decoration: none