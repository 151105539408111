.news
    padding: var(--offset) 0 80px 0
    background: var(--color-light)

.news__title
    margin-bottom: 80px
    text-align: center
    width: 100%

.news h2
    font-size: calc(26px + 14 * ((100vw - 375px) / (1920 - 375)))