.map-price
    position: relative
    background-color: var(--color-light)

.map-price__wrap
    display: grid
    position: relative
    padding-top: 70px
    aspect-ratio: 1440 / 910
    width: 100%
    max-width: max-content
    height: max-content
    margin: 0 auto

.map-price__car
    width: 100%
    max-width: 1400px

.map-price__item
    display: grid
    gap: 10px
    position: absolute
    pointer-events: none

.map-price__heading
    font-size: 20px
    font-style: normal
    font-weight: 600
    line-height: normal
    text-transform: uppercase

.map-price__char
    font-size: 18px
    font-style: normal
    font-weight: 400
    line-height: normal

.map-price__clone
    display: none

/* Absolute Elements

.map-price__roof
    left: 44.5%
    top: 17%

.map-price__wing-back
    left: -5%
    top: 34%

.map-price__hood
    right: 15%
    top: 30%

.map-price__bumper-back
    left: -6%
    bottom: 33%

.map-price__bumper-front
    right: -5%
    bottom: 29%

.map-price__step
    left: 12%
    bottom: 13%

.map-price__door
    left: 35%
    bottom: 10%

.map-price__wing-front
    bottom: 9%
    right: 21%

@media (max-width: 1700px)
    .map-price__car
        width: 70%
        position: relative
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)

    .map-price__roof
        top: 23%

    .map-price__hood
        top: 34%

    .map-price__bumper-front
        right: 4%

    .map-price__wing-front, .map-price__door
        bottom: 16%

    .map-price__step
        bottom: 20%

    .map-price__bumper-back
        left: 5%
        bottom: 37%

    .map-price__wing-back
        left: 11%
        top: 29%

@media (max-width: 1200px)
    .map-price__roof
        top: 18%

    .map-price__wing-back
        left: 4%

    .map-price__bumper-back
        left: 2%

    .map-price__step
        bottom: 17%

    .map-price__door
        bottom: 11%

    .map-price__wing-front
        bottom: 10%
        right: 26%

    .map-price__bumper-front
        right: 3%
        bottom: 17%

    .map-price__hood
        top: 29%
        right: 10%

@media (max-width: 1100px)
    .map-price__wrap .map-price__item > *
        display: none
        text-indent: -9999px

    .map-price__item
        width: 50px
        height: 50px
        border-radius: 50%
        background-color: var(--color-base)
        box-shadow: 0 0 0 rgb(175 153 107 / 80%)
        animation: map-price--pulse 3s infinite
        pointer-events: auto

    @keyframes map-price--pulse
        0%
            -moz-box-shadow: 0 0 0 0 rgba(255, 178, 30, 0.4)
            box-shadow: 0 0 0 0 rgba(212, 98, 98, 0.4)
            background-color: rgb(255 126 30)

        70%
            -moz-box-shadow: 0 0 0 10px rgba(255, 126, 30, 0)
            box-shadow: 0 0 0 10px rgba(212, 98, 98, 0)
            background-color: rgb(255 178 30)

        100%
            -moz-box-shadow: 0 0 0 0 rgba(255, 178, 30, 0)
            box-shadow: 0 0 0 0 rgba(212, 98, 98, 0)
            background-color: rgb(255 126 30)

    .map-price__car
        width: 100%

    .map-price__clone
        width: calc(100vw - 40px)
        margin: 0 auto
        padding-top: 33px
        display: grid
        gap: 9px
        text-align: center

    .map-price__item--active
        box-shadow: 0 0 0 rgb(175 153 107 / 80%)
        animation: map-price-active--pulse 3s infinite

        &::before
            content: ''
            width: 10px
            height: 10px
            border-radius: 50%
            background-color: var(--color-bg)
            opacity: 0.5s
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)

    @keyframes map-price-active--pulse
        0%
            -moz-box-shadow: 0 0 0 0 rgba(255, 102, 0, 0.4)
            box-shadow: 0 0 0 0 rgba(255, 102, 0, 0.4)
            background-color: rgb(255 126 30)

        70%
            -moz-box-shadow: 0 0 0 10px rgba(255, 126, 30, 0)
            box-shadow: 0 0 0 10px rgba(212, 98, 98, 0)
            background-color: rgb(255 178 30)

        100%
            -moz-box-shadow: 0 0 0 0 rgba(255, 178, 30, 0)
            box-shadow: 0 0 0 0 rgba(212, 98, 98, 0)
            background-color: rgb(255 126 30)

@media (max-width: 680px)
    .map-price__item
        width: 40px
        height: 40px

    .map-price__hood
        top: 35%

    .map-price__roof
        top: 20%

    .map-price__wing-back
        left: 10%
        top: 30%

    .map-price__bumper-back
        left: 3%

    .map-price__bumper-front
        right: 5%

@media (max-width: 500px)
    .map-price__item
        width: 30px
        height: 30px

    .map-price__bumper-front
        bottom: 10%

    .map-price__wing-front
        bottom: 2%

    .map-price__door
        left: 39%
        bottom: 5%

    .map-price__step
        bottom: 8%

    .map-price__bumper-back
        left: 3%
        bottom: 26%






//.map_price
//  //min-height: 100vh
//  //width: 100%
//  background-color: #F5F5F5
//
//  &__title
//    font-size: 80px
//    font-weight: 900
//    text-align: center
//    color: transparent
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 30px
//    padding-bottom: 30px
//
//    &__title_copy
//      font-size: 40px
//      font-weight: 900
//      text-align: center
//      color: black
//      -webkit-text-stroke-width: 0px
//      -webkit-text-stroke-color: transparent
//      position: relative
//      top: -75px
//
//  &__body
//    //min-height: 80vh
//    padding: 20px
//    position: relative
//    padding-bottom: 100px
//
//
//    &__image
//      display: block
//      margin: auto
//      //position: absolute
//      background-size: cover
//      width: 70%
//      //height: 70%
//
//    &__radius
//      position: absolute
//      display: flex
//      flex-direction: column
//      justify-content: space-between
//
//
//      &__1
//        top: 0
//        left: 10%
//
//      &__2
//        bottom: 50%
//        left: 5%
//
//      &__3
//        bottom: 15%
//        left: 15%
//
//      &__4
//        bottom: 10%
//        left: 40%
//
//      &__5
//        bottom: 5%
//        left: 60%
//
//      &__6
//        bottom: 40%
//        right: 5%
//
//      &__7
//        top: 15%
//        right: 15%
//
//      &__8
//        top: 0
//        right: 40%
//
//
//      &__title
//        font-size: 20px
//        font-weight: 600
//        text-transform: uppercase
//        margin-bottom: 5px
//
//      &__description
//        font-size: 18px
//        font-weight: 400
//        margin-bottom: 5px
//
//
//
//
