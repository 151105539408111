.accordion__data
    -webkit-transition: height 0.1s
    -o-transition: height 0.1s
    transition: height 0.1s
    overflow: hidden

    &:not(.accordion__data--active)
        display: none

.accordion__item--active .price-accordion__title::before
    transform: rotate(180deg)