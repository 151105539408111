.reviews-page
    padding: var(--offset) 0 80px 0
    background: var(--color-light)

.reviews-page__item
    display: grid
    grid-template-columns: max-content 1fr

.reviews-page__image
    max-width: 120px
    max-height: 180px

.reviews-page__info
    display: grid
    grid-template-rows: repeat(4, max-content)
    gap: 10px
    padding: 10px

.reviews-page__text
    font-size: calc(14px + 6 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 400

.reviews-page__full > a
    color: var(--color-base)

