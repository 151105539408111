.portfolio
  background-color: var(--color-bg)

.portfolio__title
  margin-bottom: 80px

.portfolio__wrap
  display: grid
  grid-template-columns: repeat(4, 1fr)
  gap: 20px

.portfolio__item
  border-radius: 8px
  overflow: hidden

  img
    display: block
    width: 100%
    height: 100%
    object-fit: cover

@media (max-width: 1100px)
  .portfolio__wrap
    grid-template-columns: repeat(3, 1fr)

@media (max-width: 1000px)
  .portfolio__title
    margin-bottom: 50px

@media (max-width: 800px)
  .portfolio__wrap
    grid-template-columns: repeat(2, 1fr)

@media (max-width: 680px)
  .portfolio__wrap
    gap: 15px

  .portfolio__item
    border-radius: 4px


//.portfolio
//  width: 100%
//  background-color: black
//
//  &__title
//    font-size: 65px
//    background-color: black
//    font-weight: 900
//    text-align: center
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 50px
//    padding-bottom: 30px
//    text-transform: uppercase
//
//  &__body
//    display: flex
//    flex-direction: row
//    justify-content: space-between
//    flex-wrap: wrap
//    padding-bottom: 30px
//
//    &__item
//      //width: 50%
//      flex-basis: 24%
//      margin-bottom: 15px
//
//      &#{&} img
//        background-size: cover
//
//
//
//
