.services
  background-color: var(--color-bg)

.services__wrapper
  display: grid
  grid-template-columns: repeat(4, 1fr)
  row-gap: 25px
  column-gap: 20px

.services__title
  margin-bottom: 15px

  .title__bg
    font-size: calc(60px + 20 * ((100vw - 375px) / (1920 - 375)))

.services__item
  display: grid
  grid-template-rows: repeat(2, max-content) 1fr
  align-items: center
  gap: 20px
  padding: 20px
  border-radius: 8px
  border: 2px solid rgba(247, 152, 23, 0.3)
  background: var(--color-gray)
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)

.services__item__image img
  display: block
  width: 100%
  max-width: unset
  margin: 0 auto
  border-radius: 8px
  overflow: hidden

.services__item__title
  color: var(--color-base)
  font-size: 25px
  font-style: normal
  font-weight: 600
  line-height: normal
  text-align: center

.services__item__description
  color: var(--color-white-80)
  text-align: center
  font-size: 20px
  font-style: normal
  font-weight: 500
  line-height: normal

@media (min-width: 1921px)
  .services__title .title__bg
    font-size: 80px

@media (max-width: 1160px)
  .services__wrapper
    grid-template-columns: repeat(3, 1fr)

@media (max-width: 1000px)
  .services__wrapper
    grid-template-columns: repeat(2, 1fr)

  .services__title
    margin-bottom: 50px

@media (max-width: 565px)
  .services__wrapper
    grid-template-columns: minmax(min-content, 335px)
    justify-content: center

@media (max-width: 430px)
  .services__wrapper
    grid-template-columns: 1fr



//.services
//  min-height: auto
//  width: 100%
//  padding-bottom: 80px
//  background-color: black
//
//  &__title
//    font-size: 65px
//    background-color: black
//    font-weight: 900
//    text-align: center
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 50px
//    padding-bottom: 30px
//
//  &__body
//    display: flex
//    flex-direction: row
//    justify-content: center
//    //justify-content: space-between
//    //background-color: white
//    flex-wrap: wrap
//    padding-bottom: 30px
//    //max-height: 100%
//    transition: all 1s
//
//    &__item
//      height: 473px
//      width: calc((100% - 200px)  / 4)
//      padding: 10px
//      margin-bottom: 10px
//      margin-right: 5px
//      margin-left: 5px
//      display: flex
//      flex-direction: column
//      justify-content: space-between
//      border: 1px solid
//      border-color: rgba(247, 152, 23, 0.7)
//      border-radius: 10px
//      //transition: all 1s
//
//      &__image
//        height: 50%
//        width: 100%
//        background-color: red
//        border-radius: 5px
//
//      &__title
//        color: #F79817
//        font-size: 25px
//        font-weight: 700
//        text-align: center
//
//      &__description
//        color: white
//        font-size: 20px
//        font-weight: 400
//        text-align: center
//
//    &__show
//      background-color: #F79817
//      margin: 0 auto
//      width: max-content
//      padding: 15px 20px
//      font-size: 20px
//      font-weight: 600
//      border-radius: 10px
//      cursor: pointer
//      color: white
//
//
//      &:hover
//        color: black
//
//
//  .mobile_hide
//    display: none
//
//@media(max-width: 992px)
//  .services
//    &__body
//      &__item
//        width: calc((100% - 200px) / 2)
//
//
//@media(max-width: 520px)
//  .services
//    &__body
//      &__item
//        width: calc((100% - 100px) / 1)