.models
    background-color: var(--color-light)

.models__title
    margin-bottom: 80px

.models__wrapper
    position: relative
    justify-content: center
    display: grid
    grid-template-columns: repeat(4, max-content)
    column-gap: 16px
    row-gap: 65px

.models__item
    /*position: absolute;
    /*opacity: 0;
    /*visibility: hidden;
    /*height: 0;
    display: grid
    grid-template-rows: repeat(2, max-content)
    gap: 15px
    transition: 0.3s
    justify-content: center

    &:hover >  .models__item__image
        border: 2px solid var(--color-base)

    &:hover > .models__item__title
        color: var(--color-base)

.models__item__image
    border-radius: 8px
    background-color: var(--color-white)
    border: 2px solid var(--color-white)
    display: block
    align-self: center
    width: 250px
    height: 150px

    img
        border-radius: 8px
        margin: 0 auto
        display: block
        height: 100%

.models__item__title
    font-family: Inter
    color: var(--color-black)
    font-size: 18px
    font-style: normal
    font-weight: 900
    line-height: normal
    text-align: center
    text-transform: uppercase

.models__item--visible
    position: static
    opacity: 1
    visibility: visible
    height: auto

.models__button
    margin-top: 80px

.models__notification
    font-family: Inter
    color: var(--color-black)
    font-size: 30px
    font-style: normal
    font-weight: 900
    line-height: normal
    text-align: center
    text-transform: uppercase

/*Adaptive to Models

@media (max-width: 1300px)
    .models__wrapper
        grid-template-columns: repeat(3, 1fr)

@media (max-width: 1000px)
    .models__title
        margin-bottom: 50px

    .models__wrapper
        grid-template-columns: repeat(2, 1fr)
        gap: 20px

@media(max-width: 565px)
    .models__item__image
        width: 100%
        height: unset
        min-height: max-content

//.brands
//  //min-height: 100vh
//  //width: 100%
//  background-color: rgba(247, 152, 23, .1)
//
//  &__title
//    font-size: 60px
//    font-weight: 900
//    text-align: center
//    color: transparent
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 30px
//    padding-bottom: 10px
//
//    &__title_copy
//      font-size: 28px
//      font-weight: 900
//      text-align: center
//      color: black
//      -webkit-text-stroke-width: 0px
//      -webkit-text-stroke-color: transparent
//      position: relative
//      top: -55px
//
//  &__body
//    //min-height: 80vh
//    padding: 20px 0 50px 0
//    display: flex
//    flex-direction: row
//    justify-content: space-between
//    flex-wrap: wrap
//
//    &__item
//      //padding: 15px
//      display: flex
//      flex-direction: column
//      flex-basis: calc(90% / 6)
//      margin-bottom: 25px
//      border: 1px solid transparent
//      border-radius: 10px
//
//      &:hover
//        border-color: #F79817
//
//      &:hover &__title
//        color: #F79817
//
//      &__image
//        width: 80%
//        background-size: contain
//        display: block
//        margin: auto
//        //flex-basis: 80%
//
//
//      &__title
//        font-size: 18px
//        font-weight: 600
//        color: black
//        text-align: center
//        display: block
//        margin-top: 20px
//        margin-bottom: 15px
//
//
//
//      &__show
//        color: black
//        background-color: #F79817
//        font-size: 20px
//        font-weight: 600
//        border-radius: 5px
//        padding: 15px 20px
//        margin: auto
//        margin-top: 50px
//        cursor: pointer
//
//        &:hover
//          color: white
//
//.mobile_hide
//  display: none
//
//
//
//



//.models
//  //min-height: 100vh
//  //width: 100%
//  background-color: rgba(247, 152, 23, .1)
//
//  &__title
//    font-size: 60px
//    font-weight: 900
//    text-align: center
//    color: transparent
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 30px
//    padding-bottom: 10px
//    text-transform: uppercase
//
//    &__title_copy
//      font-size: 28px
//      font-weight: 900
//      text-align: center
//      color: black
//      -webkit-text-stroke-width: 0px
//      -webkit-text-stroke-color: transparent
//      position: relative
//      top: -55px
//      text-transform: uppercase
//
//  &__body
//    //min-height: 80vh
//    padding: 20px 0 50px 0
//    display: flex
//    flex-direction: row
//    justify-content: space-between
//    flex-wrap: wrap
//
//    &__item
//      //padding: 15px
//      display: flex
//      flex-direction: column
//      flex-basis: calc(90% / 6)
//      margin-bottom: 25px
//
//      &__image
//        width: 100%
//        background-size: cover
//
//      &__title
//        font-size: 18px
//        font-weight: 600
//        color: black
//        text-align: center
//        margin-top: 20px
//        margin-bottom: 15px
//        //float: left
//        //margin-right: 25px
//
//      &__show
//        color: black
//        background-color: #F79817
//        font-size: 20px
//        font-weight: 600
//        border-radius: 5px
//        padding: 15px 20px
//        margin: auto
//        margin-top: 50px
//        cursor: pointer
//
//        &:hover
//          color: white
//
//.mobile_hide
//  display: none
//
//
//
//
