\:root
  --header-height: 200px
  --header-width: 1316px

header
  position: fixed
  left: 0
  top: 0
  height: var(--header-height)
  width: 100%
  background-color: var(--color-bg)
  transition: 0.2s
  z-index: 100

  a:hover
    text-decoration: none
    color: unset

.header__wrap
  display: grid
  position: relative
  box-sizing: border-box
  grid-template-rows: max-content max-content
  align-content: space-between
  height: 100%
  width: 100%
  max-width: var(--header-width)
  margin: 0 auto
  padding: 15px 50px 26px 50px
  font-family: 'Inter'
  color: var(--color-white)

.header__location
  display: none

.office
  display: grid
  grid-template-columns: repeat(3, max-content)
  justify-content: space-between
  padding: 0
  list-style-type: none

.office__item
  text-align: center

.office__address
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: normal

.office__phone
  color: var(--color-base)
  font-size: 20px
  font-style: normal
  font-weight: 600
  line-height: normal
  text-decoration: none

.header__bottom
  display: grid
  grid-template-columns: max-content 1fr
  align-items: center
  gap: 14px

  nav
    display: grid

.header__action
  display: grid
  height: 100%

.logo
  display: grid

  img
    max-height: 40px
    width: auto

.header__phone-button
  display: none

.menu
  display: grid
  grid-auto-flow: column
  grid-auto-columns: max-content
  margin: 0
  padding: 0
  gap: 14px
  align-self: end
  align-items: center

ul.menu, .menu ul
  list-style-type: none

.menu__item
  display: inline-grid

.menu__link
  padding: 8px 5px
  color: var(--color-white)
  font-size: 20px
  font-style: normal
  font-weight: 600
  line-height: normal
  text-transform: uppercase
  text-decoration: none
  transition: 0.3s

  &:hover
    color: var(--color-base)

//#uslugi
//  pointer-events: none

/* Триггер

.menu-trigger
  display: none
  width: 20px
  height: 16px
  position: relative
  justify-self: end

.menu-trigger__line
  width: 20px
  height: 3px
  background-color: var(--color-white)
  transition: all 0.3s
  border-radius: 2px

  &:first-child
    top: 0
    left: 0

  &:nth-child(2)
    top: 5px
    left: 0

  &:last-child
    bottom: 0
    left: 0

.menu-trigger--active .menu-trigger__line
  &:first-child
    top: 6px
    transform: rotate(45deg)

  &:nth-child(2)
    display: none

  &:last-child
    bottom: 7px
    transform: rotate(-45deg)

/* Раскрывающееся меню

.menu-drop
  display: grid
  grid-template-columns: repeat(4, 1fr)
  gap: 37px
  width: 100vw
  max-width: calc(var(--container) - 100px)
  padding: 66px 23px 40px 23px
  position: fixed
  left: 50%
  transform: translate(-50%, -100px)
  top: calc(var(--header-height) - 26px)
  background-color: var(--color-bg)
  opacity: 0
  visibility: hidden
  z-index: -100
  transition: 0.1s

.menu-drop__back
  display: none
  grid-column: 1 / -1
  grid-template-columns: max-content 1fr
  gap: 8px
  align-items: center
  justify-content: start
  padding: 15px 15px 15px 0

  &::before
    content: ''
    display: inline-block
    width: 0
    height: 0
    border-left: 6px solid transparent
    border-right: 6px solid transparent
    border-top: 10px solid var(--color-base)
    transition: 0.1s
    transform: rotate(90deg)

.menu-drop__widget
  display: grid
  gap: 15px
  grid-auto-rows: max-content

.menu-drop__widget-title
  color: var(--color-base)
  font-size: 20px
  font-weight: 700
  text-transform: uppercase

.menu-drop__widget-list
  display: grid
  gap: 5px
  padding: 0

.menu-drop__widget-item
  padding: 5px 0

.menu-drop__widget-link
  color: var(--color-white-80)
  font-size: 17px
  font-weight: 400
  text-decoration: none
  transition: 0.3s

  &:hover
    color: var(--color-base)

@media (min-width: 1071px)
  .menu__item:hover .menu-drop
    opacity: 1
    visibility: visible
    z-index: 1
    transform: translate(-50%, 0)

/* Minify

header.header--minify
  --header-height: 60px

.header--minify
  .header__wrap
    grid-template-rows: 1fr
    padding: 10px 50px 10px 50px

    > .header__top
      display: none

  .menu-drop
    top: calc(var(--header-height) - 13px)

  .menu
    justify-self: end

@media (max-width: 1366px)
  \:root
    --header-height: 160px

  .menu
    gap: 10px
    justify-self: end

  .menu__link
    font-size: 16px

  /* Minify

  .header--minify .menu-drop
    top: calc(var(--header-height) - 16px)

@media (max-width: 1150px)
  .menu
    gap: 8px

  .menu__link
    font-size: 15px

  .office__address, .office__phone
    font-size: 16px

@media (max-width: 1070px)
  .header__inner
    position: absolute
    right: 0
    transform: translateX(100%)
    top: var(--header-height)
    width: 100%
    padding: 0 50px 50px 50px
    height: calc(100vh - var(--header-height))
    background-color: var(--color-bg)
    transition: 0.1s
    overflow-x: auto

  .header--mobile
    .header__inner
      transform: translateX(0)

    .header__phone-button
      display: grid
      height: 45px
      font-size: 18px
      padding: 0 13px
      text-decoration: none

    .logo
      display: none

  .menu
    width: 100%
    grid-auto-flow: row
    grid-auto-columns: auto

  .menu-trigger
    display: grid
    gap: 2px

  .menu-trigger--active .menu-trigger__line
    position: absolute

  .menu__item_multiple > .menu__link
    display: grid
    grid-template-columns: repeat(2, max-content)
    align-items: center
    justify-content: space-between

    &::after
      content: ''
      display: inline-block
      width: 0
      height: 0
      border-left: 6px solid transparent
      border-right: 6px solid transparent
      border-top: 10px solid var(--color-base)
      transition: 0.1s
      transform: rotate(-90deg)

  .menu-drop
    height: calc(100vh - var(--header-height))
    right: 0
    left: unset
    top: 0
    padding: 0 50px
    transform: translate(-100%, 0)
    grid-template-columns: 1fr 1fr
    gap: 20px
    overflow-y: auto

  .header--minify .menu-drop
    top: 0

  .menu-drop__widget
    margin-bottom: 20px

  .menu-drop__back
    display: grid

  .menu__item_multiple--active .menu-drop
    opacity: 1
    visibility: visible
    z-index: 10
    transform: translate(0, 0)

@media (max-width: 680px)
  \:root
    --header-height: 60px

  .header__wrap
    padding: 0 20px
    align-content: center

  .header__inner
    display: grid
    gap: 30px
    padding: 30px 15px

  .header__location
    display: grid
    gap: 10px
    grid-auto-flow: column
    justify-content: start
    align-items: center
    text-transform: uppercase

  .header__location--active.header__location::before
    transform: rotate(180deg)

  .header__location::before
    content: ''
    display: inline-block
    width: 0
    height: 0
    border-left: 5px solid transparent
    border-right: 5px solid transparent
    border-top: 6px solid var(--color-white)
    transition: 0.1s

  .header__inner
    grid-auto-rows: max-content

    .office
      display: none
      height: 0
      opacity: 0
      overflow: hidden

      &.office--active
        display: grid
        height: auto
        opacity: 1
        overflow: visible

  .office
    padding-top: 30px
    grid-template-columns: 1fr
    gap: 20px
    justify-content: start
    transition: 50ms

  .office__item
    position: relative
    padding-left: 30px
    text-align: left

    &::before
      content: ''
      display: inline-block
      width: 20px
      height: 20px
      position: absolute
      left: 0
      top: 0
      background: radial-gradient(100% 100% at 50% 50%, var(--color-base) calc(50% - 1px), transparent 50%)

  .office__item--active::before
    background: radial-gradient(10px 10px at 50% 50%, var(--color-black) calc(50% - 1px), transparent 50%), radial-gradient(100% 100% at 50% 50%, var(--color-base) calc(50% - 1px), transparent 50%)

  .menu
    gap: 5px

  .menu__link
    font-size: 25px

  .menu-drop
    padding: 0 20px

  /* Minify

  .header--minify .header__wrap
    padding: 10px 20px 10px 20px
