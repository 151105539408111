.team
  background-color: var(--color-light)

.team__title
  margin-bottom: 80px

.team__wrapper
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr))
  gap: 20px

.team__item
  display: grid
  grid-template-rows: repeat(3, max-content)
  align-items: start
  justify-content: center
  gap: 13px
  background: var(--color-light)

  h2
    margin: 0

.team__item__image img
  border-radius: 8px
  display: block
  margin: 0 auto 12px auto

.team__item__title
  min-height: 98px
  color: var(--color-black)
  font-size: 40px
  font-weight: 700
  text-align: center
  text-transform: unset

.team__item__description
  min-height: 60px
  color: var(--color-black-70)
  font-size: 25px
  font-style: normal
  font-weight: 500
  line-height: normal
  text-align: center

@media(max-width: 1200px)
  .team__item:last-child
    grid-column: 1/-1



@media (max-width: 1000px)
  .team__title
    margin-bottom: 50px

  .team__wrapper
    gap: 50px 20px

  .team__item__description
    min-height: unset

@media(max-width: 919px)
  .team__item:last-child
    grid-column: unset


//.masters
//  //min-height: 100vh
//  //width: 100%
//  background-color: rgba(247, 152, 23, .1)
//
//  &__title
//    font-size: 60px
//    font-weight: 900
//    text-align: center
//    color: transparent
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 30px
//    padding-bottom: 10px
//
//    &__title_copy
//      font-size: 28px
//      font-weight: 900
//      text-align: center
//      color: black
//      -webkit-text-stroke-width: 0px
//      -webkit-text-stroke-color: transparent
//      position: relative
//      top: -55px
//
//  &__body
//    //min-height: 80vh
//    padding: 20px 0 50px 0
//    display: flex
//    flex-direction: row
//    justify-content: space-between
//
//    &__item
//      padding: 15px
//      display: flex
//      flex-direction: column
//      flex-basis: 25%
//
//      &__image
//        background-size: cover
//        border-radius: 50%
//
//      &__title
//        font-size: 30px
//        font-weight: 700
//        color: black
//        text-align: center
//        margin-top: 20px
//        margin-bottom: 15px
//        //float: left
//        //margin-right: 25px
//
//      &__description
//        font-size: 20px
//        font-weight: 500
//        color: black
//        text-align: center
//        //display: block
//        //margin: auto
//        //margin-left: 0
//        //float: left
//
//
//
//
//
//
//
