@charset "UTF-8";
:root {
  --container: 1316px;
  --offset: 80px;
  --color-base-60: rgba(247, 152, 23, 0.6);
  --color-base: #F79817;
  --color-black: #000000;
  --color-black-90: rgba(0, 0, 0, 0.9);
  --color-black-80: rgba(0, 0, 0, 0.8);
  --color-black-70: rgba(0, 0, 0, 0.7);
  --color-bg: #000000;
  --color-light: #FAF8EB;
  --color-white: #FFFFFF;
  --color-gray: #151515;
  --color-white-80: rgba(255, 255, 255, 0.8);
  --color-white-70: rgba(255, 255, 255, 0.7);
  --button-height: 80px;
  --button-sm-height: 75px;
  --fr: calc((1216px - calc(20px * 11)) / 12);
  --gap: 20px;
  --one-third: calc((var(--fr) * 4) + (var(--gap) * 3));
  --one-four: calc((var(--fr) * 3) + (var(--gap) * 2));
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), url("/assets/fonts/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), url("/assets/fonts/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"), url("/assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), url("/assets/fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: local("Montserrat ExtraBold"), url("/assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: local("Montserrat Black"), url("/assets/fonts/Montserrat-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: local("Inter Light"), url("/assets/fonts/Inter-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter Regular"), url("/assets/fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: local("Inter Medium"), url("/assets/fonts/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: local("Inter SemiBold"), url("/assets/fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local("Inter Bold"), url("/assets/fonts/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: local("Inter ExtraBold"), url("/assets/fonts/Inter-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: local("Inter Black"), url("/assets/fonts/Inter-Black.ttf") format("truetype");
}
body {
  min-height: 100vh;
  font-family: "Montserrat";
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-black);
}

.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page-wrapper .page-main {
  flex-grow: 1;
}

p {
  margin: 0 0 30px 0;
}

ul {
  margin: 0;
}

img {
  width: 100%;
  max-width: max-content;
  height: auto;
}

input[type=text], input[type=email], input[type=number], input[type=date], input[type=password], input[type=tel], input[type=submit] {
  -webkit-appearance: none;
  height: 60px;
  width: 100%;
  padding: 0 20px;
  border-radius: 8px;
  border: 2px solid var(--color-base-60);
  background-color: var(--color-bg);
  font-size: 20px;
  color: var(--color-white);
}
input[type=text][readonly], input[type=email][readonly], input[type=number][readonly], input[type=date][readonly], input[type=tel][readonly] {
  opacity: 0.5;
}
input[type=checkbox] {
  -webkit-appearance: none;
  display: none;
}
input[type=checkbox] ~ label::before {
  -webkit-appearance: none;
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: var(--color-base);
  border-radius: 3px;
}
input[type=checkbox]:checked ~ label::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(calc(-50% - 4px));
  width: 23px;
  height: 23px;
  background-image: url("/assets/images/check-white.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
input[type=checkbox] ~ label {
  cursor: pointer;
}
input[type=radio] ~ label {
  cursor: pointer;
}

select {
  -webkit-appearance: none;
  width: 100%;
  background-color: var(--color-bg);
  border-radius: 8px;
  border: 2px solid var(--color-base-60);
  height: 60px;
  color: var(--color-base);
  font-size: calc(18px + 2 * (100vw - 375px) / 1545);
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 20px;
  appearance: none;
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: var(--container);
  margin: 0 auto;
  padding: 0 50px;
}

.section {
  padding: var(--offset) 0;
}

.button {
  -webkit-appearance: none;
  display: grid;
  height: var(--button-height);
  padding: 0 35px;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  background-color: var(--color-base);
  color: var(--color-black);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: solid 2px var(--color-base);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.button_sm {
  font-size: 20px;
  height: var(--button-sm-height);
}

.button_center {
  margin: 0 auto;
}

.button:hover {
  background-color: var(--color-white);
  color: var(--color-base);
}

.button:hover > a {
  color: var(--color-base);
}

.title {
  position: relative;
  width: max-content;
  margin: 0 auto;
}

.title__bg {
  font-family: Inter;
  font-size: calc(35px + 45 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 4.4px;
  text-transform: uppercase;
  color: var(--color-light);
  opacity: 0.6;
  text-shadow: -2px -2px 0 var(--color-base), 2px -2px 0 var(--color-base), -2px 2px 0 var(--color-base), 2px 2px 0 var(--color-base);
}

.title__bg_black {
  color: var(--color-black);
}

.title__content {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: Inter;
  text-align: center;
  color: var(--color-black);
  font-size: calc(20px + 20 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

h1, .h1 {
  margin: 0;
}

h2, .h2 {
  margin: 0 0 25px 0;
  color: var(--color-base);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

h3, .h3 {
  margin: 0 0 18px 0;
  color: var(--color-base);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.drop__item {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  height: 0;
  display: grid;
  grid-template-rows: repeat(2, max-content);
  gap: 15px;
  transition: 0.3s;
}

.drop__item--visible {
  position: static;
  opacity: 1;
  visibility: visible;
  height: auto;
}

@media (min-width: 1921px) {
  .title__content {
    font-size: 40px;
  }
  .breadcrumb__item a, .breadcrumb__item:last-child a {
    font-size: 20px;
  }
  .breadcrumb__item:after {
    height: 16px;
  }
  select {
    font-size: 20px;
  }
}
@media (max-width: 680px) {
  :root {
    --offset: 50px;
    --button-height: 75px;
  }
  /*--button-sm-height: 75px; */
  .container {
    padding: 0 20px;
  }
  .title__bg {
    letter-spacing: 2px;
  }
  select {
    font-weight: normal;
  }
}
@media (max-width: 374px) {
  .step__title .title__bg {
    font-size: 28px;
  }
}
.accordion__data {
  -webkit-transition: height 0.1s;
  -o-transition: height 0.1s;
  transition: height 0.1s;
  overflow: hidden;
}
.accordion__data:not(.accordion__data--active) {
  display: none;
}

.accordion__item--active .price-accordion__title::before {
  transform: rotate(180deg);
}

:root {
  --header-height: 200px;
  --header-width: 1316px;
}

header {
  position: fixed;
  left: 0;
  top: 0;
  height: var(--header-height);
  width: 100%;
  background-color: var(--color-bg);
  transition: 0.2s;
  z-index: 100;
}
header a:hover {
  text-decoration: none;
  color: unset;
}

.header__wrap {
  display: grid;
  position: relative;
  box-sizing: border-box;
  grid-template-rows: max-content max-content;
  align-content: space-between;
  height: 100%;
  width: 100%;
  max-width: var(--header-width);
  margin: 0 auto;
  padding: 15px 50px 26px 50px;
  font-family: "Inter";
  color: var(--color-white);
}

.header__location {
  display: none;
}

.office {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  justify-content: space-between;
  padding: 0;
  list-style-type: none;
}

.office__item {
  text-align: center;
}

.office__address {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.office__phone {
  color: var(--color-base);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.header__bottom {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 14px;
}
.header__bottom nav {
  display: grid;
}

.header__action {
  display: grid;
  height: 100%;
}

.logo {
  display: grid;
}
.logo img {
  max-height: 40px;
  width: auto;
}

.header__phone-button {
  display: none;
}

.menu {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  margin: 0;
  padding: 0;
  gap: 14px;
  align-self: end;
  align-items: center;
}

ul.menu, .menu ul {
  list-style-type: none;
}

.menu__item {
  display: inline-grid;
}

.menu__link {
  padding: 8px 5px;
  color: var(--color-white);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
}
.menu__link:hover {
  color: var(--color-base);
}

/* Триггер */
.menu-trigger {
  display: none;
  width: 20px;
  height: 16px;
  position: relative;
  justify-self: end;
}

.menu-trigger__line {
  width: 20px;
  height: 3px;
  background-color: var(--color-white);
  transition: all 0.3s;
  border-radius: 2px;
}
.menu-trigger__line:first-child {
  top: 0;
  left: 0;
}
.menu-trigger__line:nth-child(2) {
  top: 5px;
  left: 0;
}
.menu-trigger__line:last-child {
  bottom: 0;
  left: 0;
}

.menu-trigger--active .menu-trigger__line:first-child {
  top: 6px;
  transform: rotate(45deg);
}
.menu-trigger--active .menu-trigger__line:nth-child(2) {
  display: none;
}
.menu-trigger--active .menu-trigger__line:last-child {
  bottom: 7px;
  transform: rotate(-45deg);
}

/* Раскрывающееся меню */
.menu-drop {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 37px;
  width: 100vw;
  max-width: calc(var(--container) - 100px);
  padding: 66px 23px 40px 23px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -100px);
  top: calc(var(--header-height) - 26px);
  background-color: var(--color-bg);
  opacity: 0;
  visibility: hidden;
  z-index: -100;
  transition: 0.1s;
}

.menu-drop__back {
  display: none;
  grid-column: 1/-1;
  grid-template-columns: max-content 1fr;
  gap: 8px;
  align-items: center;
  justify-content: start;
  padding: 15px 15px 15px 0;
}
.menu-drop__back::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid var(--color-base);
  transition: 0.1s;
  transform: rotate(90deg);
}

.menu-drop__widget {
  display: grid;
  gap: 15px;
  grid-auto-rows: max-content;
}

.menu-drop__widget-title {
  color: var(--color-base);
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.menu-drop__widget-list {
  display: grid;
  gap: 5px;
  padding: 0;
}

.menu-drop__widget-item {
  padding: 5px 0;
}

.menu-drop__widget-link {
  color: var(--color-white-80);
  font-size: 17px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.3s;
}
.menu-drop__widget-link:hover {
  color: var(--color-base);
}

@media (min-width: 1071px) {
  .menu__item:hover .menu-drop {
    opacity: 1;
    visibility: visible;
    z-index: 1;
    transform: translate(-50%, 0);
  }
}
/* Minify */
header.header--minify {
  --header-height: 60px;
}

.header--minify .header__wrap {
  grid-template-rows: 1fr;
  padding: 10px 50px 10px 50px;
}
.header--minify .header__wrap > .header__top {
  display: none;
}
.header--minify .menu-drop {
  top: calc(var(--header-height) - 13px);
}
.header--minify .menu {
  justify-self: end;
}

@media (max-width: 1366px) {
  :root {
    --header-height: 160px;
  }
  .menu {
    gap: 10px;
    justify-self: end;
  }
  .menu__link {
    font-size: 16px;
  }
  /* Minify */
  .header--minify .menu-drop {
    top: calc(var(--header-height) - 16px);
  }
}
@media (max-width: 1150px) {
  .menu {
    gap: 8px;
  }
  .menu__link {
    font-size: 15px;
  }
  .office__address, .office__phone {
    font-size: 16px;
  }
}
@media (max-width: 1070px) {
  .header__inner {
    position: absolute;
    right: 0;
    transform: translateX(100%);
    top: var(--header-height);
    width: 100%;
    padding: 0 50px 50px 50px;
    height: calc(100vh - var(--header-height));
    background-color: var(--color-bg);
    transition: 0.1s;
    overflow-x: auto;
  }
  .header--mobile .header__inner {
    transform: translateX(0);
  }
  .header--mobile .header__phone-button {
    display: grid;
    height: 45px;
    font-size: 18px;
    padding: 0 13px;
    text-decoration: none;
  }
  .header--mobile .logo {
    display: none;
  }
  .menu {
    width: 100%;
    grid-auto-flow: row;
    grid-auto-columns: auto;
  }
  .menu-trigger {
    display: grid;
    gap: 2px;
  }
  .menu-trigger--active .menu-trigger__line {
    position: absolute;
  }
  .menu__item_multiple > .menu__link {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
  }
  .menu__item_multiple > .menu__link::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid var(--color-base);
    transition: 0.1s;
    transform: rotate(-90deg);
  }
  .menu-drop {
    height: calc(100vh - var(--header-height));
    right: 0;
    left: unset;
    top: 0;
    padding: 0 50px;
    transform: translate(-100%, 0);
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    overflow-y: auto;
  }
  .header--minify .menu-drop {
    top: 0;
  }
  .menu-drop__widget {
    margin-bottom: 20px;
  }
  .menu-drop__back {
    display: grid;
  }
  .menu__item_multiple--active .menu-drop {
    opacity: 1;
    visibility: visible;
    z-index: 10;
    transform: translate(0, 0);
  }
}
@media (max-width: 680px) {
  :root {
    --header-height: 60px;
  }
  .header__wrap {
    padding: 0 20px;
    align-content: center;
  }
  .header__inner {
    display: grid;
    gap: 30px;
    padding: 30px 15px;
  }
  .header__location {
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    text-transform: uppercase;
  }
  .header__location--active.header__location::before {
    transform: rotate(180deg);
  }
  .header__location::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid var(--color-white);
    transition: 0.1s;
  }
  .header__inner {
    grid-auto-rows: max-content;
  }
  .header__inner .office {
    display: none;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .header__inner .office.office--active {
    display: grid;
    height: auto;
    opacity: 1;
    overflow: visible;
  }
  .office {
    padding-top: 30px;
    grid-template-columns: 1fr;
    gap: 20px;
    justify-content: start;
    transition: 50ms;
  }
  .office__item {
    position: relative;
    padding-left: 30px;
    text-align: left;
  }
  .office__item::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    background: radial-gradient(100% 100% at 50% 50%, var(--color-base) calc(50% - 1px), transparent 50%);
  }
  .office__item--active::before {
    background: radial-gradient(10px 10px at 50% 50%, var(--color-black) calc(50% - 1px), transparent 50%), radial-gradient(100% 100% at 50% 50%, var(--color-base) calc(50% - 1px), transparent 50%);
  }
  .menu {
    gap: 5px;
  }
  .menu__link {
    font-size: 25px;
  }
  .menu-drop {
    padding: 0 20px;
  }
  /* Minify */
  .header--minify .header__wrap {
    padding: 10px 20px 10px 20px;
  }
}
.banner {
  padding: calc(var(--offset) + var(--header-height)) 0 60px 0;
  background-color: var(--color-light);
}

.banner__item {
  display: grid;
  position: relative;
  height: 100%;
  padding: var(--offset) 32px;
  aspect-ratio: 1216/760;
  box-sizing: border-box;
  background-color: var(--color-bg);
  border-radius: 20px;
  overflow: hidden;
}

.banner__info {
  display: grid;
  position: relative;
  grid-template-rows: repeat(3, max-content) 1fr;
  gap: 10px;
  height: 100%;
  align-items: start;
  color: var(--color-white);
  z-index: 1;
}

.banner__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.banner__logo {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: 8px;
  font-size: calc(26px + 34 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

.banner__logo-ident {
  padding: 12px 8px;
  background-color: var(--color-base);
  color: var(--color-black);
  border-radius: 10px;
}

.banner__title {
  margin: 0 0 20px 0;
  font-size: calc(20px + 10 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.banner__descriptor {
  display: grid;
  gap: 20px;
  margin-bottom: 40px;
}

.banner__descriptor__item {
  color: var(--color-base);
  font-size: calc(18px + 7 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.banner__button {
  justify-self: start;
  font-size: calc(20px + 5 * (100vw - 375px) / 1545);
}

/*Offer */
.banner_offer {
  padding: 40px 0 130px;
  background-color: var(--color-gray);
}
.banner_offer .banner__item {
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  padding: 0;
  min-height: fit-content;
  background-color: unset;
  border-radius: 0;
  aspect-ratio: unset;
  gap: 30px;
}

.banner__header {
  color: var(--color-base);
  font-size: calc(24px + 24 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.banner_offer .banner__info {
  padding: 15px 0;
  grid-template-rows: repeat(4, max-content);
  gap: 30px;
}
.banner_offer .banner__image {
  position: unset;
}
.banner_offer .banner__descriptor {
  margin-bottom: unset;
}
.banner_offer .banner__title {
  margin: 0;
}

/*Banner-merc */
.banner__header_highlight {
  color: var(--color-base);
  font-size: calc(30px + 20 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

/*Banner-service */
.banner_subservice {
  padding: 80px 0;
  background-color: var(--color-bg);
}
.banner_subservice .banner__item {
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  padding: 0;
  min-height: unset;
  background-color: unset;
  border-radius: 0;
}
.banner_subservice .banner__info {
  padding: 0;
  grid-template-rows: repeat(2, max-content);
  gap: 50px;
}
.banner_subservice .banner__image {
  position: unset;
}
.banner_subservice img {
  border: 2px solid var(--color-base);
  border-radius: 8px;
}
.banner_subservice .banner__descriptor {
  margin-bottom: unset;
}
.banner_subservice .banner__title {
  margin: 0;
}

/* Фиксируем шрифты для больших экранов */
@media (min-width: 1921px) {
  .banner__logo {
    font-size: 60px;
  }
  .banner__header, .banner__header_white, .banner__header_highlight {
    font-size: 50px;
  }
  .banner__title {
    font-size: 30px;
  }
  .banner__descriptor__item, .banner__button {
    font-size: 25px;
  }
}
@media (max-width: 940px) {
  .banner .banner__item {
    aspect-ratio: unset;
    height: max-content;
  }
  .banner .banner__info {
    max-width: 68%;
  }
}
@media (max-width: 565px) {
  .banner {
    background-color: var(--color-bg);
  }
  .banner .banner__item {
    padding: 0;
    border-radius: unset;
  }
  .banner .banner__info {
    grid-template-rows: repeat(2, max-content) 1fr;
    max-width: unset;
  }
  .banner .banner__logo {
    display: none;
  }
  .banner .banner__title {
    margin-bottom: 40px;
  }
  .banner .banner__descriptor {
    gap: 15px;
  }
  .banner__action {
    position: relative;
    align-self: center;
  }
  .banner .banner__item img {
    position: static;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .banner .banner__button {
    height: 60px;
    padding: 0 12px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
/*Banner_offer */
@media (max-width: 1170px) {
  .banner_offer {
    padding: 40px 0;
  }
  .banner_offer .banner__info {
    max-width: 95%;
    padding: 40px 0;
    grid-template-rows: repeat(4, max-content);
    gap: 40px;
  }
  .banner_offer .banner__item {
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    padding: 0;
    max-height: 760px;
  }
}
@media (max-width: 1090px) {
  .banner_offer .banner__info {
    /*max-width: 90%; */
    padding: 30px 0;
    grid-template-rows: repeat(4, max-content);
    gap: 30px;
  }
}
@media (max-width: 1030px) {
  .banner_offer .banner__info {
    /*max-width: 100%; */
    padding: 20px 0;
    grid-template-rows: repeat(4, max-content);
    gap: 20px;
  }
}
@media (max-width: 970px) {
  .banner_offer .banner__item {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 930px) {
  .banner_offer {
    padding: 0 0 40px;
    /*justify-self: unset; */
    /*transform: translateY(-50%); */
  }
  .banner_offer .banner_item {
    /*margin-bottom: 40px; */
    overflow: unset;
  }
  .banner_offer .banner__info {
    grid-template-rows: repeat(3, max-content);
    gap: 10px;
    padding: 0 0 20px;
  }
  .banner_offer .banner__descriptor {
    gap: 10px;
  }
}
@media (max-width: 840px) {
  .banner_offer .banner__item {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
  .banner_offer .banner__item .banner__image {
    position: relative;
    width: 50%;
    height: 100%;
    object-fit: cover;
    left: 50%;
  }
  .banner_offer .banner__button {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin: 0;
  }
  .banner_offer .banner__action {
    position: relative;
    align-self: center;
  }
}
@media (max-width: 560px) {
  .banner_offer .banner__button {
    height: 60px;
    padding: 0 12px;
  }
}
@media (max-width: 470px) {
  .banner_offer .banner__image {
    display: none;
  }
  .banner_offer .banner__button {
    position: relative;
    margin-top: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
/*Banner_subservice*/
@media (max-width: 1200px) {
  .banner_subservice .banner__item {
    aspect-ratio: unset;
    grid-template-columns: repeat(2, 1fr);
    justify-content: unset;
    gap: 20px;
  }
}
@media (max-width: 840px) {
  .banner_subservice .banner__info {
    grid-template-rows: max-content 1fr;
    gap: 30px;
  }
}
@media (max-width: 770px) {
  .banner_subservice .banner__action {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .banner_subservice .banner__item {
    grid-template-columns: 1fr;
  }
  .banner_subservice #col_btn {
    display: block;
    position: relative;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
@media (max-width: 680px) {
  .banner_subservice {
    padding: 30px 0;
  }
  .banner_subservice #col_btn {
    height: 60px;
    padding: 0 12px;
  }
}
@media (max-width: 470px) {
  .banner_subservice .banner__image {
    display: none;
  }
  .banner_subservice .banner__action {
    grid-template-columns: 1fr;
  }
  .banner_subservice #col_btn {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.banner-vacancy {
  background-color: var(--color-bg);
  padding: 0 0 80px 0;
}
.banner-vacancy .banner__header {
  margin-bottom: 80px;
}
.banner-vacancy .title__bg {
  letter-spacing: unset;
}
.banner-vacancy .banner__title {
  color: var(--color-base-60);
  font-family: Inter;
  font-size: calc(15px + 15 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.banner-vacancy .banner__item {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  background-color: unset;
  border-radius: 0;
  aspect-ratio: unset;
}
.banner-vacancy .banner__block {
  padding: 0;
  display: grid;
  position: relative;
  grid-template-rows: repeat(2, max-content);
  gap: 40px;
  height: 100%;
  align-items: start;
  z-index: 1;
}
.banner-vacancy .banner__item__ul {
  padding: 0;
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
}
.banner-vacancy .banner__item__ul li > a {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 15px;
  align-items: start;
  color: var(--color-base);
  font-family: Inter;
  font-size: calc(12px + 11 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.banner-vacancy .banner__item__ul li > a::before {
  content: "";
  background-color: var(--color-base);
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-self: start;
  margin-top: 0.6em;
  transform: translateY(-50%);
}
.banner-vacancy .banner__image {
  position: unset;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
.banner-vacancy img {
  border-radius: 20px;
  border: 1px solid var(--color-base);
}

/* Фиксируем шрифты для больших экранов */
@media (max-width: 1300px) {
  .banner-vacancy .banner__item {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}
@media (max-width: 900px) {
  .banner-vacancy .banner__block {
    gap: 20px;
  }
  .banner-vacancy .banner__item__ul {
    gap: 12px;
  }
  .banner-vacancy .banner__header {
    margin-bottom: 50px;
  }
}
@media (max-width: 700px) {
  .banner-vacancy {
    padding-bottom: 40px;
  }
  .banner-vacancy .banner__item {
    grid-template-columns: max-content;
  }
  .banner-vacancy .banner__block {
    gap: 15px;
  }
  .banner-vacancy .banner__item__ul {
    gap: 10px;
  }
  .banner-vacancy .banner__image {
    display: none;
  }
  .banner-vacancy .banner__item__ul li > a::before {
    width: 10px;
    height: 10px;
  }
}
@media (max-width: 560px) {
  .banner-vacancy .banner__header {
    margin-bottom: 40px;
  }
  .banner-vacancy .banner__title {
    margin: 0;
  }
}
@media (max-width: 450px) {
  .banner-vacancy {
    padding: 0 0 30px;
  }
  .banner-vacancy .title__bg {
    max-width: calc(100vw - 40px);
    text-align: center;
  }
  .banner-vacancy .banner__header {
    margin-bottom: 20px;
  }
  .banner-vacancy .banner__item__ul {
    gap: 8px;
  }
  .banner-vacancy .banner__item__ul li > a::before {
    width: 8px;
    height: 8px;
  }
  .banner-vacancy .banner__block {
    gap: 10px;
  }
}
.services {
  background-color: var(--color-bg);
}

.services__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 25px;
  column-gap: 20px;
}

.services__title {
  margin-bottom: 15px;
}
.services__title .title__bg {
  font-size: calc(60px + 20 * (100vw - 375px) / 1545);
}

.services__item {
  display: grid;
  grid-template-rows: repeat(2, max-content) 1fr;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid rgba(247, 152, 23, 0.3);
  background: var(--color-gray);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.services__item__image img {
  display: block;
  width: 100%;
  max-width: unset;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
}

.services__item__title {
  color: var(--color-base);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.services__item__description {
  color: var(--color-white-80);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (min-width: 1921px) {
  .services__title .title__bg {
    font-size: 80px;
  }
}
@media (max-width: 1160px) {
  .services__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1000px) {
  .services__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .services__title {
    margin-bottom: 50px;
  }
}
@media (max-width: 565px) {
  .services__wrapper {
    grid-template-columns: minmax(min-content, 335px);
    justify-content: center;
  }
}
@media (max-width: 430px) {
  .services__wrapper {
    grid-template-columns: 1fr;
  }
}
.map-price {
  position: relative;
  background-color: var(--color-light);
}

.map-price__wrap {
  display: grid;
  position: relative;
  padding-top: 70px;
  aspect-ratio: 1440/910;
  width: 100%;
  max-width: max-content;
  height: max-content;
  margin: 0 auto;
}

.map-price__car {
  width: 100%;
  max-width: 1400px;
}

.map-price__item {
  display: grid;
  gap: 10px;
  position: absolute;
  pointer-events: none;
}

.map-price__heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.map-price__char {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.map-price__clone {
  display: none;
}

/* Absolute Elements */
.map-price__roof {
  left: 44.5%;
  top: 17%;
}

.map-price__wing-back {
  left: -5%;
  top: 34%;
}

.map-price__hood {
  right: 15%;
  top: 30%;
}

.map-price__bumper-back {
  left: -6%;
  bottom: 33%;
}

.map-price__bumper-front {
  right: -5%;
  bottom: 29%;
}

.map-price__step {
  left: 12%;
  bottom: 13%;
}

.map-price__door {
  left: 35%;
  bottom: 10%;
}

.map-price__wing-front {
  bottom: 9%;
  right: 21%;
}

@media (max-width: 1700px) {
  .map-price__car {
    width: 70%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .map-price__roof {
    top: 23%;
  }
  .map-price__hood {
    top: 34%;
  }
  .map-price__bumper-front {
    right: 4%;
  }
  .map-price__wing-front, .map-price__door {
    bottom: 16%;
  }
  .map-price__step {
    bottom: 20%;
  }
  .map-price__bumper-back {
    left: 5%;
    bottom: 37%;
  }
  .map-price__wing-back {
    left: 11%;
    top: 29%;
  }
}
@media (max-width: 1200px) {
  .map-price__roof {
    top: 18%;
  }
  .map-price__wing-back {
    left: 4%;
  }
  .map-price__bumper-back {
    left: 2%;
  }
  .map-price__step {
    bottom: 17%;
  }
  .map-price__door {
    bottom: 11%;
  }
  .map-price__wing-front {
    bottom: 10%;
    right: 26%;
  }
  .map-price__bumper-front {
    right: 3%;
    bottom: 17%;
  }
  .map-price__hood {
    top: 29%;
    right: 10%;
  }
}
@media (max-width: 1100px) {
  .map-price__wrap .map-price__item > * {
    display: none;
    text-indent: -9999px;
  }
  .map-price__item {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--color-base);
    box-shadow: 0 0 0 rgba(175, 153, 107, 0.8);
    animation: map-price--pulse 3s infinite;
    pointer-events: auto;
  }
  @keyframes map-price--pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 178, 30, 0.4);
      box-shadow: 0 0 0 0 rgba(212, 98, 98, 0.4);
      background-color: rgb(255, 126, 30);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 126, 30, 0);
      box-shadow: 0 0 0 10px rgba(212, 98, 98, 0);
      background-color: rgb(255, 178, 30);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 178, 30, 0);
      box-shadow: 0 0 0 0 rgba(212, 98, 98, 0);
      background-color: rgb(255, 126, 30);
    }
  }
  .map-price__car {
    width: 100%;
  }
  .map-price__clone {
    width: calc(100vw - 40px);
    margin: 0 auto;
    padding-top: 33px;
    display: grid;
    gap: 9px;
    text-align: center;
  }
  .map-price__item--active {
    box-shadow: 0 0 0 rgba(175, 153, 107, 0.8);
    animation: map-price-active--pulse 3s infinite;
  }
  .map-price__item--active::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-bg);
    opacity: 0.5s;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  @keyframes map-price-active--pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 102, 0, 0.4);
      box-shadow: 0 0 0 0 rgba(255, 102, 0, 0.4);
      background-color: rgb(255, 126, 30);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 126, 30, 0);
      box-shadow: 0 0 0 10px rgba(212, 98, 98, 0);
      background-color: rgb(255, 178, 30);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 178, 30, 0);
      box-shadow: 0 0 0 0 rgba(212, 98, 98, 0);
      background-color: rgb(255, 126, 30);
    }
  }
}
@media (max-width: 680px) {
  .map-price__item {
    width: 40px;
    height: 40px;
  }
  .map-price__hood {
    top: 35%;
  }
  .map-price__roof {
    top: 20%;
  }
  .map-price__wing-back {
    left: 10%;
    top: 30%;
  }
  .map-price__bumper-back {
    left: 3%;
  }
  .map-price__bumper-front {
    right: 5%;
  }
}
@media (max-width: 500px) {
  .map-price__item {
    width: 30px;
    height: 30px;
  }
  .map-price__bumper-front {
    bottom: 10%;
  }
  .map-price__wing-front {
    bottom: 2%;
  }
  .map-price__door {
    left: 39%;
    bottom: 5%;
  }
  .map-price__step {
    bottom: 8%;
  }
  .map-price__bumper-back {
    left: 3%;
    bottom: 26%;
  }
}
.example {
  position: relative;
  background-color: var(--color-bg);
}

.example_offset {
  padding: var(--offset) 0 80px 0;
}

.example__title {
  margin-bottom: 90px;
}

.example-swiper__inner {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr max-content;
  gap: 34px;
}

.example-swiper__info {
  display: grid;
  gap: 30px;
  grid-auto-rows: max-content;
  align-items: start;
  color: var(--color-base);
}

.example-swiper__title {
  margin: 0;
  font-size: 32px;
  font-weight: 700;
}

.example-swiper__works {
  display: grid;
  gap: 30px;
}

.example-swiper__works-label {
  font-size: calc(18px + 7 * (100vw - 375px) / 1545);
  font-weight: 600;
  color: var(--color-white);
}

.example-swiper__works-list {
  padding: 0;
  font-size: calc(18px + 5 * (100vw - 375px) / 1545);
  font-weight: 500;
  list-style-type: none;
  display: grid;
  gap: 17px;
}

.example-swiper__works-item {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 20px;
}

.example-swiper__works-name {
  text-decoration: underline;
}

.example-swiper__works-price {
  color: var(--color-white);
}

.example-swiper__works-descriptor {
  font-size: calc(18px + 7 * (100vw - 375px) / 1545);
  font-weight: 500;
  color: var(--color-white-80);
}

.example-swiper__price {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 10px;
  align-items: center;
}

.example-swiper__price-label {
  font-size: calc(16px + 9 * (100vw - 375px) / 1545);
  font-weight: 600;
  color: var(--color-white);
}

.example-swiper__price-value {
  font-size: calc(18px + 12 * (100vw - 375px) / 1545);
  font-weight: 800;
}

.example-swiper__gallery {
  width: 100%;
  min-width: 0;
  max-width: 578px;
  padding-top: 32px;
}

.swiper-gallery {
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.swiper-gallery__wrap {
  position: relative;
}

.swiper-gallery__item img {
  display: block;
  max-height: 385px;
  height: max-content;
  width: 100%;
  max-width: 578px;
}

.swiper-gallery-slave__item {
  border-radius: 8px;
  overflow: hidden;
}
.swiper-gallery-slave__item img {
  display: block;
}

.example-swiper__prev, .example-swiper__next {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--color-gray);
  border: solid 2px var(--color-base);
  color: var(--color-base, var(--color-base));
}

.example-swiper__prev {
  left: 64px;
}

.example-swiper__next {
  right: 64px;
}

.swiper-gallery__prev, .swiper-gallery__next {
  --swiper-navigation-size: 25px;
  width: 50px;
  height: 50px;
  top: unset;
  bottom: 0;
  border-radius: 8px;
  background-color: var(--color-base);
  border: solid 2px var(--color-base);
  color: var(--color-white, var(--color-white));
  z-index: 1;
}

.swiper-gallery__prev {
  left: 0;
  border-radius: 0 8px 0 8px;
}

.swiper-gallery__next {
  right: 0;
  border-radius: 8px 0 8px 0;
}

/* Фиксируем шрифты для больших экранов */
@media (min-width: 1921px) {
  .example-swiper__price-value {
    font-size: 30px;
  }
  .example-swiper__works-label, .example-swiper__works-descriptor, .example-swiper__price-label {
    font-size: 25px;
  }
  .example-swiper__works-list {
    font-size: 23px;
  }
}
@media (max-width: 1550px) {
  .example-swiper__next, .example-swiper__prev {
    opacity: 0.7;
    width: 75px;
    height: 75px;
  }
}
@media (max-width: 1140px) {
  .example-swiper__next, .example-swiper__prev {
    display: none;
  }
  .example__title {
    margin-bottom: 50px;
  }
  .example__title .title__bg {
    max-width: calc(100vw - 40px);
    text-align: center;
    margin-bottom: 0;
  }
  .example-swiper__inner {
    grid-template-columns: 1fr;
  }
  .swiper-gallery {
    margin-bottom: 11px;
  }
  .example-swiper__gallery {
    order: -1;
    padding-top: 0;
  }
}
.price {
  background-color: var(--color-light);
}

.price__title {
  margin-bottom: 70px;
}

.price-accordion {
  display: grid;
  gap: 15px;
  margin-bottom: 15px;
}

.price-accordion__item {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: start;
  align-items: center;
  gap: 15px;
  padding: 22px 35px;
  border: 2px solid var(--color-base);
  background-color: var(--color-white);
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.price-accordion__title {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: 56px;
  font-family: "Inter";
  font-size: calc(20px + 5 * (100vw - 375px) / 1545);
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  color: var(--color-black);
}

.price-accordion__data {
  padding: 0 68px 5px 68px;
  color: var(--color-black);
  font-size: calc(18px + 7 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.price-accordion__title::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid var(--color-base);
  transition: 0.1s;
}

.price-table {
  display: grid;
  gap: 17px;
}

.price-table__row {
  display: grid;
  gap: 5px;
  position: relative;
  grid-template-columns: max-content 1fr;
  line-height: 1;
}

.price-table__value {
  display: grid;
  grid-template-columns: 1fr minmax(170px, min-content);
  gap: 5px;
  align-items: end;
  text-align: right;
}
.price-table__value::before {
  content: "";
  border-bottom: 1px dashed var(--color-black);
}

.button-collection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.button-collection__item {
  border-radius: 20px;
  font-size: calc(18px + 2 * (100vw - 375px) / 1545);
}

/*Price-offset */
.price_offset {
  background-color: var(--color-light);
  padding: var(--offset) 0 80px 0;
}

.price__subtitle {
  color: var(--color-black);
  text-align: center;
  font-size: calc(20px + 5 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.price-title .price__title {
  margin-bottom: 50px;
}

.price_offset .price-table__row {
  display: grid;
  gap: 5px;
  position: relative;
  grid-template-columns: minmax(min-content, max-content) 1fr;
}

.price_offset .price-table__value {
  grid-template-columns: 1fr minmax(140px, min-content);
}

@media (min-width: 1921px) {
  .price-accordion__title, .price-accordion__data, .price__subtitle {
    font-size: 25px;
  }
  .button-collection__item {
    font-size: 20px;
  }
}
@media (max-width: 1000px) {
  .price__title {
    margin-bottom: 50px;
  }
  .price-accordion__title {
    gap: 25px;
  }
  .price-table__value {
    grid-template-columns: 1fr minmax(100px, min-content);
  }
  .price-accordion__data {
    padding: 0 25px 10px 37px;
  }
}
@media (max-width: 800px) {
  .button-collection {
    grid-template-columns: 1fr 1fr;
  }
  .button-collection__item {
    font-weight: 900;
  }
  .button-collection__item:last-child {
    grid-column: 1/-1;
  }
  .price-table__row {
    grid-template-columns: 2fr 1fr;
  }
  .price-table__value:before {
    border-bottom: unset;
  }
}
@media (max-width: 640px) {
  .price-accordion__data {
    text-decoration-line: none;
  }
  .price-table {
    gap: 25px;
  }
  .price-table__row {
    grid-template-columns: 1fr;
  }
  .price-table__value {
    grid-template-columns: 1fr;
    text-align: left;
  }
  .price-table__value::before {
    display: none;
  }
  .price_offset .price-table__row {
    grid-template-columns: 1fr;
  }
  .price_offset .price-table__value {
    grid-template-columns: 1fr;
    text-align: left;
  }
}
@media (max-width: 565px) {
  .price-accordion__item {
    padding: 25px;
  }
  .button-collection {
    grid-template-columns: 1fr;
  }
}
.about {
  background-color: var(--color-bg);
}

.limiter {
  display: grid;
  grid-template-rows: 1fr;
  gap: 80px;
}

.about__item p {
  margin: 0;
  font-size: calc(18px + 7 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
}

.about__item {
  color: var(--color-white-80);
  display: grid;
  grid-auto-flow: row;
  gap: 30px;
  font-size: calc(18px + 7 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
}
.about__item ul li {
  display: grid;
  grid-template-columns: max-content 1fr;
  justify-content: start;
  gap: 25px;
  align-items: center;
  font-size: calc(16px + 9 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
}
.about__item ul li::before {
  content: "";
  background-color: var(--color-base);
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.about__item ul li > ul {
  grid-column: 1/-1;
}
.about__item a {
  color: var(--color-base);
}

.about h2 {
  margin: 0;
  color: var(--color-base);
  font-size: calc(25px + 15 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.about ul {
  list-style: none;
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
}

.about ol li {
  font-size: calc(16px + 9 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
}

.about table {
  font-size: calc(9px + 9 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 300;
}

.about__button {
  margin-top: 80px;
}

.limiter {
  overflow: hidden;
  position: relative;
  color: black;
  transition: max-height 0.3s ease;
}
.limiter .bottom {
  bottom: 0;
  width: 100%;
  height: 60px;
  opacity: 1;
  transition: 0.3s;
}

.read-more-checker {
  opacity: 0;
  position: absolute;
}
.read-more-checker:checked ~ .limiter {
  max-height: none;
}
.read-more-checker:checked ~ .limiter .bottom {
  opacity: 0;
  transition: 0.3s;
}
.read-more-checker:checked ~ .read-more-button:before {
  content: "Свернуть «";
}
.read-more-checker ~ .read-more-button:before {
  content: "Развернуть »";
}

.read-more-button {
  display: grid;
  cursor: pointer;
  /*display: inline-block;*/
  color: #777;
  border-radius: 20px;
  background: var(--color-base);
  text-transform: uppercase;
}

/* Фиксируем шрифты для больших экранов */
@media (min-width: 1921px) {
  .about p, .about ul li {
    font-size: 25px;
  }
  .about h2 {
    font-size: 40px;
  }
}
@media (max-width: 1000px) {
  .about__button {
    margin-top: 50px;
  }
  .about__ul {
    padding: 0;
  }
  .limiter {
    gap: 50px;
  }
}
@media (max-width: 560px) {
  .about ul {
    padding: 0;
  }
  .about ul li {
    gap: 10px;
  }
}
.portfolio {
  background-color: var(--color-bg);
}

.portfolio__title {
  margin-bottom: 80px;
}

.portfolio__wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.portfolio__item {
  border-radius: 8px;
  overflow: hidden;
}
.portfolio__item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1100px) {
  .portfolio__wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1000px) {
  .portfolio__title {
    margin-bottom: 50px;
  }
}
@media (max-width: 800px) {
  .portfolio__wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 680px) {
  .portfolio__wrap {
    gap: 15px;
  }
  .portfolio__item {
    border-radius: 4px;
  }
}
.team {
  background-color: var(--color-light);
}

.team__title {
  margin-bottom: 80px;
}

.team__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 20px;
}

.team__item {
  display: grid;
  grid-template-rows: repeat(3, max-content);
  align-items: start;
  justify-content: center;
  gap: 13px;
  background: var(--color-light);
}
.team__item h2 {
  margin: 0;
}

.team__item__image img {
  border-radius: 8px;
  display: block;
  margin: 0 auto 12px auto;
}

.team__item__title {
  min-height: 98px;
  color: var(--color-black);
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  text-transform: unset;
}

.team__item__description {
  min-height: 60px;
  color: var(--color-black-70);
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

@media (max-width: 1200px) {
  .team__item:last-child {
    grid-column: 1/-1;
  }
}
@media (max-width: 1000px) {
  .team__title {
    margin-bottom: 50px;
  }
  .team__wrapper {
    gap: 50px 20px;
  }
  .team__item__description {
    min-height: unset;
  }
}
@media (max-width: 919px) {
  .team__item:last-child {
    grid-column: unset;
  }
}
.video-reviews {
  background-color: var(--color-bg);
}

.video-reviews__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.video-reviews__title {
  margin-bottom: 80px;
}

.video-reviews__item {
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid var(--color-base);
  background: var(--color-gray);
}

.video-reviews__item__image img {
  border-radius: 8px;
  display: block;
  margin-bottom: 21px;
}

.video-reviews__item__title {
  color: var(--color-base);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.video-reviews__item__name {
  color: var(--color-white);
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.video-reviews__item__description {
  color: var(--color-white);
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 11px;
}

@media (max-width: 1160px) {
  .video-reviews__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1000px) {
  .video-reviews__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .video-reviews__title {
    margin-bottom: 50px;
  }
  .video-reviews__item:last-child {
    grid-column: 1/-1;
    margin: 0 auto;
    max-width: 408px;
  }
}
@media (max-width: 565px) {
  .video-reviews__wrapper {
    grid-template-columns: minmax(min-content, 335px);
    justify-content: center;
  }
}
@media (max-width: 430px) {
  .video-reviews__wrapper {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 374px) {
  .video-reviews__title .title__bg_black {
    font-size: 28px;
  }
}
.brands {
  background-color: var(--color-light);
}

.brands__title {
  margin-bottom: 80px;
}

.brands__wrapper {
  position: relative;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 20px;
  row-gap: 65px;
}

.brands__item {
  /*position: absolute; */
  /*opacity: 0; */
  /*visibility: hidden; */
  /*height: 0; */
  display: grid;
  grid-template-rows: repeat(2, max-content);
  gap: 15px;
  transition: 0.3s;
}
.brands__item:hover > .brands__item__image {
  border: 2px solid var(--color-base);
}
.brands__item:hover > .brands__item__title {
  color: var(--color-base);
}

.brands__item__image {
  background-color: var(--color-white);
  border: 2px solid var(--color-white);
  border-radius: 8px;
  display: block;
  align-self: center;
  width: 180px;
  height: 135px;
}
.brands__item__image img {
  margin: 0 auto;
  display: block;
  height: 100%;
}

.brands__item__title {
  font-family: Inter;
  color: var(--color-black);
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}

.brands__item--visible {
  position: static;
  opacity: 1;
  visibility: visible;
  height: auto;
}

.brands__button {
  margin-top: 80px;
}

/*Adaptive to Brands */
@media (max-width: 1300px) {
  .brands__wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
  .brands__item {
    justify-content: center;
  }
  .brands__wrapper {
    row-gap: 40px;
  }
}
@media (max-width: 1000px) {
  .brands__title {
    margin-bottom: 50px;
  }
  .brands__wrapper {
    gap: 20px;
  }
}
@media (max-width: 900px) {
  .brands__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 675px) {
  .brands__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 435px) {
  .brands__item__image {
    width: 100%;
    min-height: max-content;
  }
}
.reviews {
  position: relative;
  background-color: var(--color-bg);
}

.reviews_invert {
  position: relative;
  background-color: var(--color-light);
}

.swiper-review {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.reviews__title {
  margin-bottom: 80px;
}

.reviews-swiper__item {
  display: grid;
  grid-template-rows: max-content minmax(1fr, max-content);
  align-items: center;
  align-content: start;
  gap: 15px;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid var(--color-base);
  background: var(--color-gray);
  height: auto;
}

.reviews__item__title {
  color: var(--color-base);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  min-height: 78px;
}

.reviews__item__name {
  color: var(--color-white-80);
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reviews__item__description {
  color: var(--color-white-80);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.reviews-swiper__prev, .reviews-swiper__next {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--color-gray);
  border: solid 2px var(--color-base);
  color: var(--color-base, var(--color-base));
}

.reviews-swiper__prev {
  left: 64px;
}

.reviews-swiper__next {
  right: 64px;
}

.reviews_invert .reviews-swiper__item {
  display: grid;
  grid-template-rows: max-content minmax(1fr, max-content);
  align-items: center;
  align-content: start;
  gap: 15px;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid var(--color-base);
  background-color: var(--color-white);
  height: auto;
}
.reviews_invert .reviews__item__name, .reviews_invert .reviews__item__description {
  color: var(--color-black-80);
}
.reviews_invert .reviews-swiper__next, .reviews_invert .reviews-swiper__prev {
  background-color: unset;
}

@media (max-width: 1550px) {
  .reviews-swiper__next, .reviews-swiper__prev {
    opacity: 0.7;
    width: 75px;
    height: 75px;
  }
}
@media (max-width: 1100px) {
  .reviews__title {
    margin-bottom: 50px;
  }
}
@media (max-width: 1000px) {
  .reviews__item__description {
    font-size: 20px;
  }
}
@media (max-width: 750px) {
  .swiper-review {
    grid-template-columns: 1fr;
  }
  .reviews-swiper__item {
    width: calc(100vw - 375px);
  }
  .reviews-swiper__next, .reviews-swiper__prev {
    display: none;
  }
}
.reviews-page {
  padding: var(--offset) 0 80px 0;
  background: var(--color-light);
}

.reviews-page__item {
  display: grid;
  grid-template-columns: max-content 1fr;
}

.reviews-page__image {
  max-width: 120px;
  max-height: 180px;
}

.reviews-page__info {
  display: grid;
  grid-template-rows: repeat(4, max-content);
  gap: 10px;
  padding: 10px;
}

.reviews-page__text {
  font-size: calc(14px + 6 * (100vw - 375px) / 1545);
  font-weight: 400;
}

.reviews-page__full > a {
  color: var(--color-base);
}

.faq {
  background-color: var(--color-light);
}

.faq__title {
  margin-bottom: 20px;
}

.faq__subtitle {
  color: var(--color-black-80);
  font-size: calc(20px + 20 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 50px;
}

.faq-accordion {
  display: grid;
  gap: 15px;
  margin-bottom: 15px;
}

.faq-accordion__item {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: start;
  align-items: center;
  gap: 15px;
  padding: 35px 10px 35px 0;
  background-color: var(--color-light);
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid var(--color-black);
}

.faq-accordion__title {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  color: var(--color-black);
  font-size: calc(18px + 14 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.faq-accordion__title::after {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
  transition: 0.1s;
  background-color: var(--color-base);
  padding: 20px;
  border-radius: 50%;
  background-image: url("../../../../assets/images/arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
}

.accordion__item--active .faq-accordion__title::after {
  transform: rotate(180deg);
}

.faq-accordion__data {
  padding: 0;
  -webkit-transition: height 0.1s;
  -o-transition: height 0.1s;
  transition: height 0.1s;
  overflow: hidden;
  color: var(--color-black-80);
  font-size: calc(18px + 14 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (min-width: 1921px) {
  .faq__subtitle {
    font-size: 40px;
  }
  .faq-accordion__title, .faq-accordion__data {
    font-size: 32px;
  }
}
@media (max-width: 1000px) {
  .faq__subtitle {
    margin-bottom: 27px;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 680px) {
  .faq-accordion {
    gap: 0;
  }
  .faq-accordion__item {
    padding: 18px 0;
  }
  .faq-accordion__title::after {
    padding: 5px;
    background-size: 15px 15px;
  }
}
.faq-page {
  padding: var(--offset) 0 80px 0;
  background: var(--color-light);
}

.faq-page__title {
  margin-bottom: 80px;
  width: 100%;
  text-align: center;
}

.faq-page__item {
  display: grid;
  grid-template-rows: repeat(4, max-content);
  gap: 20px;
  padding: 10px;
}

.faq-page__header {
  font-size: calc(16px + 16 * (100vw - 375px) / 1545);
  font-weight: 600;
  color: var(--color-base);
}

.faq-page__text span {
  font-size: calc(16px + 12 * (100vw - 375px) / 1545);
  font-weight: 500;
}

.faq-page__text, .faq-page__date {
  font-size: calc(14px + 8 * (100vw - 375px) / 1545);
  font-weight: 400;
}

.faq-page__full > a {
  color: var(--color-base);
}

.question {
  background-color: var(--color-bg);
}
.question input {
  height: 80px;
}

.question__item {
  display: grid;
  grid-template-rows: repeat(2, 0.5fr);
  gap: 20px;
}

.question__title {
  color: var(--color-base);
  font-family: Montserrat;
  font-size: calc(25px + 15 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin: unset;
}

.question__description {
  color: var(--color-white-80);
  font-family: Montserrat;
  font-size: calc(20px + 12 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 30px;
}

.question__block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.question__name {
  border-radius: 12px;
  border: 1px solid var(--color-white-70);
  background: var(--color-gray);
  color: rgba(255, 255, 255, 0.7);
  font-family: Montserrat;
  font-size: calc(18px + 2 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  align-content: center;
}

.question__phone {
  border-radius: 12px;
  border: 1px solid var(--color-white-70);
  background: var(--color-gray);
  color: var(--color-white-70);
  font-family: Montserrat;
  font-size: calc(18px + 2 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

@media (min-width: 1921px) {
  .question__title {
    font-size: 40px;
  }
  .question__description {
    font-size: 32px;
  }
  .question__name, .question__phone {
    font-size: 20px;
  }
}
@media (max-width: 1000px) {
  .question__block {
    grid-template-columns: unset;
    grid-template-rows: repeat(3, max-content);
  }
}
.contacts {
  background-color: var(--color-light);
}

.contacts__title {
  margin-bottom: 80px;
}

.contacts__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.contacts__item {
  display: grid;
  grid-template-rows: repeat(5, max-content) 0.5fr 0.5fr;
  align-items: start;
  gap: 15px;
  border-radius: 8px;
  background: var(--color-bg);
  padding: 0 0 15px;
}

.contacts__item a {
  text-decoration: none;
  color: var(--color-black);
}
.contacts__item a:hover {
  color: var(--color-base);
}

.contacts__item__title {
  padding: 17px;
  min-height: 65px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--color-base);
  color: var(--color-black);
  text-align: center;
  align-items: center;
  font-size: calc(20px + 5 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

.contacts__item__description {
  color: var(--color-white);
  font-size: calc(16px + 2 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.contacts__item .button_sm {
  height: 56px;
  border-radius: 8px;
  border: 0px;
}

.button-contacts__item {
  margin: 0 25px;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 1.1;
  text-transform: uppercase;
}

/*Contacts-top */
.contacts_offset {
  padding: var(--offset) 0 80px 0;
}

/* Фиксируем шрифты для больших экранов */
@media (min-width: 1921px) {
  .contacts__item__title {
    font-size: 25px;
  }
  .contacts__item__description {
    font-size: 18px;
  }
}
@media (max-width: 1000px) {
  .contacts__title {
    margin-bottom: 50px;
  }
  .contacts__wrapper {
    grid-template-columns: unset;
    grid-template-rows: repeat(3, 1fr);
  }
  .contacts__item img {
    max-width: 100%;
  }
}
.footer_map {
  display: block;
  position: relative;
}
.footer_map .map-box {
  display: block;
  position: relative;
  z-index: 1;
}
.footer_map .map-box iframe {
  width: 100%;
  height: 800px;
}
.footer_map .map-content {
  display: block;
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 2;
}

.contacts-item {
  display: block;
  position: relative;
  max-width: 327px;
  margin: 0 auto;
  background-color: black;
  color: #ffffff;
  overflow: hidden;
  height: 100%;
}
.contacts-item__title {
  background-color: #ff5e1d;
  padding: 30px 40px;
  color: black;
  font-size: 21px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .contacts-item__title {
    font-size: calc(16px + 8.5 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .contacts-item__title {
    font-size: calc(16px + 5 * (100vw / 1920));
  }
}
.contacts-item__info {
  padding: 30px 40px;
  display: block;
  position: relative;
  overflow: hidden;
}
.contacts-item__info:before {
  content: "";
  display: block;
  width: 200px;
  height: 100px;
  background-color: #aeaeae;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotate(-25deg) translate(20px, 70px);
  z-index: 1;
}
.contacts-item__adress {
  color: white;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.375;
}
@media (max-width: 767px) {
  .contacts-item__adress {
    font-size: calc(14px + 3.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .contacts-item__adress {
    font-size: calc(14px + 2 * (100vw / 1920));
  }
}
.contacts-item__phone a {
  color: white;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.375;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .contacts-item__phone a {
    font-size: calc(14px + 3.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .contacts-item__phone a {
    font-size: calc(14px + 2 * (100vw / 1920));
  }
}
.contacts-item__phone a:hover, .contacts-item__phone a:focus, .contacts-item__phone a:active {
  text-decoration: none;
  color: #ff5e1d;
}
.contacts-item__time-work {
  color: white;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.375;
  margin: 20px 0;
}
@media (max-width: 767px) {
  .contacts-item__time-work {
    font-size: calc(14px + 3.4 * ((100vw - 320px) / 1920));
  }
}
@media (min-width: 767px) {
  .contacts-item__time-work {
    font-size: calc(14px + 2 * (100vw / 1920));
  }
}
.contacts-item__location {
  display: block;
  position: relative;
  z-index: 2;
}
.contacts-item__location a {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.contacts-item__location a .text {
  color: white;
  font-size: 11px;
  font-weight: 300;
  line-height: 14px;
}
.contacts-item__location a .uk-icon {
  font-size: 30px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px dashed #ffffff;
  margin-right: 30px;
  color: #ffffff;
}
.contacts-item__location a .uk-icon svg {
  width: 32px;
  height: 42px;
  margin-right: -16px;
  margin-bottom: -4px;
}
.contacts-item__location a:hover, .contacts-item__location a:focus, .contacts-item__location a:active {
  text-decoration: none;
}

.map {
  aspect-ratio: 1920/900;
  background-color: #262626;
}

#map {
  height: 100%;
}

.baloon {
  display: grid;
}

.baloon__inner {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 350px;
}

.baloon__content {
  display: grid;
  gap: 16px;
  width: max-content;
  padding: 24px;
  border-radius: 8px;
  background-color: #ffffff;
}

.baloon__title {
  margin-bottom: 8px;
}

.baloon__actions {
  display: grid;
  gap: 8px;
}

.baloon__actions-item {
  position: relative;
}

.baloon__actions-button {
  height: 60px;
  width: 100%;
  max-width: 315px;
  font-size: 16px;
}

.baloon__actions-float {
  opacity: 0;
  visibility: hidden;
  z-index: -100;
  position: absolute;
  bottom: 60px;
  left: 0;
  display: grid;
  gap: 12px;
  width: 100%;
  padding: 72px 24px 24px 24px;
  border-radius: 8px;
  background-color: #ffffff;
  transition: 0.2s;
}

.baloon__actions-item:hover .baloon__actions-float {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.baloon__actions-target {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  gap: 8px;
}

.baloon__actions-target::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url(/assets/images/arrow-orange.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: rotate(-90deg);
}

.baloon .close {
  display: grid;
  position: absolute;
  right: 5px;
  top: 5px;
  text-decoration: none;
  font-size: 30px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  z-index: 2;
}

[class*=-copyrights-pane] {
  display: none;
}

@media (max-width: 680px) {
  .map {
    aspect-ratio: 3/4;
  }
}
footer {
  padding: 48px 0 34px 0;
  background-color: var(--color-bg);
}

.footer__wrap {
  display: grid;
  grid-template-columns: var(--one-third) var(--one-four) 1fr;
  margin-bottom: 80px;
  gap: 20px;
}

.footer__widget {
  color: var(--color-white);
}

.footer__title {
  margin-bottom: 20px;
  font-size: calc(16px + 4 * (100vw - 375px) / 1545);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--color-white);
}

.footer__menu {
  display: grid;
  gap: 15px;
  padding: 5px 0;
  list-style-type: none;
}

.footer__menu-link {
  color: var(--color-base);
  font-size: calc(16px + 4 * (100vw - 375px) / 1545);
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}

.footer__logo {
  margin-bottom: 50px;
}

.footer__location {
  display: grid;
  gap: 10px;
}

.footer__location-item {
  font-size: 20px;
  color: var(--color-white);
  font-weight: 600;
}

.footer__common {
  display: grid;
  grid-template-rows: repeat(2, max-content) 1fr;
  align-items: end;
}

.footer__legal {
  color: var(--color-base);
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}

.footer__copyright {
  font-family: Inter;
  font-size: calc(12px + 6 * (100vw - 375px) / 1545);
  font-weight: 400;
  color: var(--color-white-80);
}

@media (min-width: 1921px) {
  .footer__title, .footer__menu-link {
    font-size: 20px;
  }
  .footer__copyright {
    font-size: 18px;
  }
}
@media (max-width: 1120px) {
  .footer__wrap {
    grid-template-columns: repeat(2, max-content);
    gap: 30px 50px;
    margin-bottom: 30px;
  }
  .footer__common {
    grid-column: 1/-1;
  }
  .footer__location {
    margin-bottom: 43px;
  }
}
@media (max-width: 1120px) {
  .footer__wrap {
    column-gap: 20px;
  }
}
@media (max-width: 565px) {
  .footer__wrap {
    grid-template-columns: max-content 1fr;
  }
  .footer__logo {
    margin: 0 auto 50px auto;
  }
  .footer__location {
    text-align: center;
  }
}
@media (max-width: 380px) {
  .footer__wrap {
    grid-template-columns: 1fr;
  }
  .footer__location-item {
    font-size: 18px;
  }
}
.promotion {
  position: relative;
  background-color: var(--color-light);
}

.promotion__title {
  margin-bottom: 80px;
}

.swiper-promotion {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.promotions-swiper__item {
  display: grid;
  grid-template-rows: max-content 70px 0.5fr max-content;
  align-items: start;
  gap: 15px;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid var(--color-base);
  background: var(--color-white);
  height: auto;
}

.promotion__item__image img {
  max-width: unset;
  border-radius: 8px;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.promotion__item__title {
  color: var(--color-base);
  font-size: calc(23px + 2 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.promotion__item__description {
  color: var(--color-black);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.promotion__item__price {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 20px;
}
.promotion__item__price .current {
  font-size: calc(16px + 8 * (100vw - 375px) / 1545);
  font-weight: 700;
}
.promotion__item__price .old {
  font-size: calc(12px + 3 * (100vw - 375px) / 1545);
  font-weight: 500;
  text-decoration: line-through;
  align-self: center;
}

.promotions-swiper__prev, .promotions-swiper__next {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: unset;
  border: solid 2px var(--color-base);
  color: var(--color-base, var(--color-base));
}

.promotions-swiper__prev {
  left: 64px;
}

.promotions-swiper__next {
  right: 64px;
}

@media (min-width: 1921px) {
  .promotion__item__title {
    font-size: 25px;
  }
}
@media (max-width: 1550px) {
  .promotions-swiper__next, .promotions-swiper__prev {
    height: 75px;
    width: 75px;
  }
  .promotion__item__image img {
    max-width: 450px;
    max-height: 450px;
  }
}
@media (max-width: 1100px) {
  .promotion__item {
    grid-template-rows: repeat(3, max-content) 1fr;
  }
}
@media (max-width: 1000px) {
  .promotion__title {
    margin-bottom: 50px;
  }
}
@media (max-width: 730px) {
  .promotions-swiper__next, .promotions-swiper__prev {
    display: none;
  }
}
.promotion-horizontal {
  background-color: var(--color-light);
  padding: var(--offset) 0 80px 0;
}

.promotion-horizontal__title {
  margin-bottom: 80px;
}

.promotion-horizontal__wrapper {
  display: grid;
  grid-template-rows: repeat(3, max-content);
  gap: 40px;
}

.promotion-horizontal__item {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  align-items: start;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid var(--color-base);
  background: var(--color-white);
}

.promotion-horizontal__item__image {
  align-self: center;
}
.promotion-horizontal__item__image img {
  border-radius: 8px;
  display: block;
  margin: 0 auto;
}

.promotion-horizontal__item__info {
  display: grid;
  grid-template-rows: repeat(3, max-content);
  align-items: start;
  gap: 40px;
}

.promotion-horizontal__item__title {
  color: var(--color-base);
  font-size: calc(23px + 5 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
  text-transform: uppercase;
  margin: 0;
}

.promotion-horizontal__item__description {
  color: var(--color-black);
  font-size: calc(20px + 10 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.promotion-horizontal .promotion__item__price {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 40px;
}
.promotion-horizontal .promotion__item__price .current {
  font-size: calc(20px + 8 * (100vw - 375px) / 1545);
  font-weight: 700;
}
.promotion-horizontal .promotion__item__price .old {
  font-size: calc(16px + 3 * (100vw - 375px) / 1545);
  font-weight: 500;
  text-decoration: line-through;
  align-self: center;
}

@media (min-width: 1921px) {
  .promotion-horizontal__item__description {
    font-size: 30px;
  }
  .promotion-horizontal__item__title {
    font-size: 28px;
  }
  .promotion-horizontal__item__ul li {
    font-size: 25px;
  }
}
@media (max-width: 1170px) {
  .promotion-horizontal__item {
    grid-template-columns: 0.5fr 1fr;
  }
}
@media (max-width: 1100px) {
  .promotion-horizontal__wrapper, .promotion-horizontal__item__info {
    gap: 20px;
  }
}
@media (max-width: 1000px) {
  .promotion-horizontal__title {
    margin-bottom: 50px;
  }
}
@media (max-width: 950px) {
  .promotion-horizontal__wrapper, .promotion-horizontal__item, .promotion-horizontal__item__info, .promotion-horizontal__item__ul {
    gap: 15px;
  }
}
@media (max-width: 840px) {
  .promotion-horizontal__item {
    grid-template-columns: unset;
    grid-template-rows: max-content 0.5fr;
    justify-content: center;
    max-width: 408px;
    margin: 0 auto;
  }
  .promotion-horizontal__item__title {
    text-align: center;
  }
  .promotion-horizontal__item__ul li::before {
    width: 10px;
    height: 10px;
  }
}
.better {
  background-color: var(--color-bg);
}

.better__title {
  margin-bottom: 80px;
}

.better__wrap {
  display: grid;
  max-width: 1028px;
  margin: 0 auto;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  gap: 25px;
}

.better__item {
  display: grid;
  max-width: 410px;
  grid-template-columns: 100px 1fr;
  gap: 20px;
  align-items: center;
}

.better__info {
  display: grid;
  grid-template-rows: 1fr max-content;
  gap: 5px;
}

.better__heading {
  font-size: calc(16px + 4 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: var(--color-white);
}

.better__descriptor {
  font-size: calc(16px + 4 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--color-white);
}

/* Фиксируем шрифты для больших экранов */
@media (min-width: 1921px) {
  .better__heading, .better__descriptor {
    font-size: 20px;
  }
}
@media (max-width: 1000px) {
  .better__title {
    margin-bottom: 50px;
  }
}
@media (max-width: 900px) {
  .better__wrap {
    grid-template-columns: max-content;
    justify-content: center;
    gap: 32px;
  }
  .better__item {
    grid-template-columns: 80px 1fr;
  }
  .better__info {
    grid-template-rows: unset;
    grid-auto-rows: max-content;
  }
}
@media (max-width: 565px) {
  .better__wrap {
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 32px;
    max-width: 335px;
  }
}
@media (max-width: 430px) {
  .better__wrap {
    max-width: 430px;
  }
}
.step {
  background-color: var(--color-light);
}

.step__title {
  margin-bottom: 80px;
}

.step__list {
  display: grid;
  max-width: 1118px;
  margin: 0 auto;
  gap: 35px;
}

.step__item {
  position: relative;
  display: grid;
  grid-template-columns: 113px 1fr;
  gap: 56px;
  align-items: center;
  counter-increment: item;
  list-style-type: none;
  color: var(--color-black-80);
  font-family: Montserrat;
  font-size: calc(18px + 12 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.step__item::before {
  content: counter(decimal-leading-zero) counter(item) ".";
  color: var(--color-base);
  font-size: calc(30px + 50 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

@media (min-width: 1921px) {
  .step__item {
    font-size: 30px;
  }
  .step__item::before {
    font-size: 80px;
  }
}
@media (max-width: 1000px) {
  .step__title {
    margin-bottom: 50px;
  }
  .step__list {
    padding: 0;
  }
  .step__item {
    grid-template-columns: minmax(max-content, 60px) 1fr;
    gap: 20px;
  }
  .step__item::before {
    align-self: start;
  }
}
@media (max-width: 430px) {
  .step__item {
    grid-template-columns: minmax(max-content, 50px) 1fr;
  }
}
@media (max-width: 375px) {
  .step__item {
    grid-template-columns: minmax(max-content, 43px) 1fr;
  }
}
.popup {
  display: none;
  opacity: 0;
  z-index: -100;
  width: 100%;
  max-width: 598px;
  padding: 40px 33px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  background-color: var(--color-bg);
  border-radius: 8px;
}

.popup--active {
  display: grid;
  opacity: 1;
  z-index: 101;
}

.popup_wrap {
  display: grid;
  gap: 18px;
}

.popup__msg {
  opacity: 0;
  visibility: hidden;
  z-index: -100;
  position: absolute;
  left: 0;
  top: 0;
  gap: 12px;
  width: 100%;
  height: 100%;
  padding: 72px 24px 24px 24px;
  border-radius: 8px;
  background-color: var(--color-black);
  transition: 0.2s;
}

.popup__msg__text {
  font-size: calc(24px + 8 * (100vw - 375px) / 1545);
  color: var(--color-base);
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.popup-overlay {
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--color-white-70);
  z-index: -100;
}

.popup-overlay--active {
  opacity: 1;
  z-index: 100;
}

.popup__close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 16px;
  right: 16px;
  display: grid;
  align-items: center;
  cursor: pointer;
}

.popup__close-line {
  width: 1px;
  height: 20px;
  position: absolute;
  left: 50%;
  top: calc(50% - 10px);
  background-color: var(--color-base);
}
.popup__close-line:first-child {
  transform: rotate(45deg);
}
.popup__close-line:last-child {
  transform: rotate(-45deg);
}

.popup label {
  display: inline-block;
  color: var(--color-white-80);
  margin-bottom: 12px;
  font-size: calc(18px + 2 * (100vw - 375px) / 1545);
  font-weight: 700;
  text-transform: uppercase;
}

.popup__title {
  font-size: calc(25px + 3 * (100vw - 375px) / 1545);
  margin-bottom: 30px;
}

.form-contacts__item + .form-contacts__submit {
  margin-top: 12px;
}

input[type=submit] {
  height: var(--button-sm-height);
  background-color: var(--color-base);
  color: var(--color-black);
  font-size: 25px;
}

.form-contacts__approve {
  position: relative;
}
.form-contacts__approve label {
  margin: 0;
  padding: 0 0 0 35px;
  font-size: calc(12px + 4 * (100vw - 375px) / 1545);
  font-weight: 500;
  text-transform: uppercase;
}

@media (min-width: 1921px) {
  .popup__title {
    font-size: 28px;
  }
  .popup label {
    font-size: 20px;
  }
  .form-contacts__approve label {
    font-size: 16px;
  }
}
@media (max-width: 680px) {
  .popup {
    width: 100%;
    max-width: calc(100vw - 40px);
  }
  .popup label {
    font-weight: normal;
  }
}
/*Breadcrumbs */
.breadcrumbs {
  padding-top: calc(40px + var(--header-height));
  padding-bottom: 40px;
}

.breadcrumbs_black {
  background-color: var(--color-bg);
}

.breadcrumbs ol {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, max-content);
  align-items: center;
  height: 30px;
}

/*gap: 10px; */
.breadcrumb__item {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  gap: 10px;
  width: max-content;
}
.breadcrumb__item:after {
  height: calc(8px + 8 * (100vw - 375px) / 1545);
  width: 16px;
  content: "";
  display: inline-block;
  background-image: url("/assets/images/breadcrumbs.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.breadcrumb__item a {
  text-decoration: none;
  color: var(--color-white);
  font-size: calc(10px + 7 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.breadcrumb__item a:hover {
  color: var(--color-base);
  text-decoration: underline;
  transition: width 0.3s ease-out;
}
.breadcrumb__item:last-child a {
  cursor: default;
  pointer-events: none;
  color: var(--color-base);
  font-size: calc(10px + 7 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.breadcrumb__item:last-child:after {
  display: none;
}

/*Адаптив breadcrumbs */
@media (max-width: 600px) {
  /*.breadcrumbs ol { */
  /*    height: 60px; */
  /*    grid-template-columns: repeat(2, max-content); */
  /*} */
  .breadcrumb__item:after {
    height: 10px;
  }
}
/*--Адаптив breadcrumbs-- */
/*Vacancy2-top */
.vacancy2_top {
  padding: var(--offset) 0 80px 0;
  background-color: var(--color-light);
}
.vacancy2_top p {
  margin: 0;
}
.vacancy2_top h2 {
  margin: 0;
}

.vacancy2__title {
  text-align: center;
  width: 100%;
}

.vacancy2_top__wrapper {
  display: grid;
  grid-auto-flow: row;
  gap: 80px;
}

#opis_txt_vak_vacansy_new {
  display: grid;
  grid-auto-flow: row;
  gap: 40px;
}

.vacansy_new_block_opis {
  display: grid;
  grid-auto-flow: row;
  gap: 40px;
}

.vacancy2_top .title__bg {
  letter-spacing: unset;
}

.vacancy2_top p {
  color: var(--color-black);
  font-size: calc(15px + 10 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#opis_txt_vak_vacansy_new span {
  color: var(--color-base);
  font-size: calc(20px + 20 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}

.adress_vacancy {
  color: var(--color-black);
  font-size: calc(15px + 10 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.adress_vacancy b {
  color: var(--color-base);
  font-size: calc(20px + 20 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.vacancy2_top ul {
  list-style: none;
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  padding: 0;
}
.vacancy2_top ul li {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 20px;
  align-items: center;
  color: var(--color-black);
  font-size: calc(15px + 10 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
}
.vacancy2_top ul li::before {
  content: "";
  background-color: var(--color-base);
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

@media (max-width: 1000px) {
  .vacancy2__title {
    max-width: calc(100vw - 40px);
    text-align: center;
  }
  .vacancy2_top__wrapper {
    gap: 60px;
  }
  .vacansy_new_block_opis {
    gap: 30px;
  }
}
@media (max-width: 700px) {
  .vacancy2_top__wrapper {
    gap: 50px;
  }
  .vacansy_new_block_opis {
    gap: 0;
  }
  #opis_txt_vak_vacansy_new {
    gap: 30px;
  }
}
@media (max-width: 480px) {
  .vacancy2_top ul > li::before {
    width: 10px;
    height: 10px;
  }
}
.our-works {
  background-color: var(--color-bg);
  padding: var(--offset) 0 80px 0;
}

.our-works__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.our-works__title {
  margin-bottom: 80px;
}

.our-works__item {
  display: grid;
  grid-template-rows: repeat(2, max-content);
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid var(--color-base);
  background: var(--color-gray);
}

.our-works__item__image img {
  border-radius: 8px;
  display: block;
}

.our-works__item__title {
  color: var(--color-base);
  font-size: calc(20px + 5 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}

.our-works__item__description {
  color: var(--color-white);
  font-size: calc(18px + 4 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.our-works__item__more {
  color: var(--color-base);
  font-size: calc(18px + 7 * (100vw - 375px) / 1545);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button__more {
  margin-top: 80px;
}

@media (min-width: 1921px) {
  .our-works__item__title, .our-works__item__more {
    font-size: 25px;
  }
  .our-works__item__description {
    font-size: 22px;
  }
}
@media (max-width: 1000px) {
  .our-works__title {
    margin-bottom: 50px;
  }
  .our-works__wrapper {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 20px;
  }
  .our-works__item:last-child {
    grid-column: 1/-1;
    margin: 0 auto;
    max-width: max-content;
  }
}
@media (max-width: 700px) {
  .our-works__wrapper {
    grid-template-columns: 1fr;
  }
}
.error {
  padding: calc(var(--offset) + var(--header-height)) 0 80px 0;
  background: var(--color-light);
}

.error h1 {
  font-size: 25px;
  font-weight: 600;
}

.error p {
  font-size: 20px;
  font-weight: 400;
}

.error a {
  color: var(--color-base);
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
}

.sitemap {
  padding: calc(var(--offset) + var(--header-height)) 0 80px 0;
  background: var(--color-light);
}
.sitemap ul.pagination {
  list-style: none;
}
.sitemap ul.pagination li {
  display: inline;
}

.news {
  padding: var(--offset) 0 80px 0;
  background: var(--color-light);
}

.news__title {
  margin-bottom: 80px;
  text-align: center;
  width: 100%;
}

.news h2 {
  font-size: calc(26px + 14 * (100vw - 375px) / 1545);
}

.models {
  background-color: var(--color-light);
}

.models__title {
  margin-bottom: 80px;
}

.models__wrapper {
  position: relative;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(4, max-content);
  column-gap: 16px;
  row-gap: 65px;
}

.models__item {
  /*position: absolute; */
  /*opacity: 0; */
  /*visibility: hidden; */
  /*height: 0; */
  display: grid;
  grid-template-rows: repeat(2, max-content);
  gap: 15px;
  transition: 0.3s;
  justify-content: center;
}
.models__item:hover > .models__item__image {
  border: 2px solid var(--color-base);
}
.models__item:hover > .models__item__title {
  color: var(--color-base);
}

.models__item__image {
  border-radius: 8px;
  background-color: var(--color-white);
  border: 2px solid var(--color-white);
  display: block;
  align-self: center;
  width: 250px;
  height: 150px;
}
.models__item__image img {
  border-radius: 8px;
  margin: 0 auto;
  display: block;
  height: 100%;
}

.models__item__title {
  font-family: Inter;
  color: var(--color-black);
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}

.models__item--visible {
  position: static;
  opacity: 1;
  visibility: visible;
  height: auto;
}

.models__button {
  margin-top: 80px;
}

.models__notification {
  font-family: Inter;
  color: var(--color-black);
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}

/*Adaptive to Models */
@media (max-width: 1300px) {
  .models__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1000px) {
  .models__title {
    margin-bottom: 50px;
  }
  .models__wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
@media (max-width: 565px) {
  .models__item__image {
    width: 100%;
    height: unset;
    min-height: max-content;
  }
}