.faq-page
    padding: var(--offset) 0 80px 0
    background: var(--color-light)

.faq-page__title
    margin-bottom: 80px
    width: 100%
    text-align: center

.faq-page__item
    display: grid
    grid-template-rows: repeat(4, max-content)
    gap: 20px
    padding: 10px

.faq-page__header
    font-size: calc(16px + 16 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 600
    color: var(--color-base)

.faq-page__text span
    font-size: calc(16px + 12 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 500

.faq-page__text, .faq-page__date
    font-size: calc(14px + 8 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 400

.faq-page__full > a
    color: var(--color-base)
