.promotion-horizontal
    background-color: var(--color-light)
    padding: var(--offset) 0 80px 0
    //padding: calc(var(--offset) + var(--header-height)) 0 60px 0 /*Нужен без крошек*/

.promotion-horizontal__title
    margin-bottom: 80px

.promotion-horizontal__wrapper
    display: grid
    grid-template-rows: repeat(3, max-content)
    gap: 40px

.promotion-horizontal__item
    display: grid
    grid-template-columns: 0.4fr 1fr
    align-items: start
    gap: 20px
    padding: 20px
    border-radius: 8px
    border: 2px solid var(--color-base)
    background: var(--color-white)

.promotion-horizontal__item__image
    align-self: center

    img
        border-radius: 8px
        display: block
        margin: 0 auto

.promotion-horizontal__item__info
    display: grid
    grid-template-rows: repeat(3, max-content)
    align-items: start
    gap: 40px

.promotion-horizontal__item__title
    color: var(--color-base)
    font-size: calc(23px + 5 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 700
    line-height: normal
    text-align: start
    text-transform: uppercase
    margin: 0

.promotion-horizontal__item__description
    color: var(--color-black)
    font-size: calc(20px + 10 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 400
    line-height: normal

.promotion-horizontal .promotion__item__price
    display: grid
    grid-template-columns: repeat(2, max-content)
    gap: 40px

    .current
        font-size: calc(20px + 8 * ((100vw - 375px) / (1920 - 375)))
        font-weight: 700

    .old
        font-size: calc(16px + 3 * ((100vw - 375px) / (1920 - 375)))
        font-weight: 500
        text-decoration: line-through
        align-self: center



//.promotion-horizontal__item__ul
//    padding: 0
//    display: grid
//    grid-auto-flow: row
//    gap: 20px
//
//    li
//        display: grid
//        grid-auto-flow: column
//        justify-content: start
//        gap: 25px
//        align-items: start
//        color: var(--color-black)
//        font-size: calc(18px + 7 * ((100vw - 375px) / (1920 - 375)))
//        font-style: normal
//        font-weight: 400
//
//        &::before
//            content: ""
//            background-color: var(--color-base)
//            display: inline-block
//            width: 15px
//            height: 15px
//            border-radius: 50%
//            align-self: start
//            margin-top: 0.6em
//            transform: translateY(-50%)

@media (min-width: 1921px)
    .promotion-horizontal__item__description
        font-size: 30px

    .promotion-horizontal__item__title
        font-size: 28px

    .promotion-horizontal__item__ul li
        font-size: 25px

@media (max-width: 1170px)
    .promotion-horizontal__item
        grid-template-columns: 0.5fr 1fr

@media (max-width: 1100px)
    .promotion-horizontal__wrapper, .promotion-horizontal__item__info
        gap: 20px

@media (max-width: 1000px)
    .promotion-horizontal__title
        margin-bottom: 50px

@media (max-width: 950px)
    .promotion-horizontal__wrapper, .promotion-horizontal__item, .promotion-horizontal__item__info, .promotion-horizontal__item__ul
        gap: 15px

@media (max-width: 840px)
    .promotion-horizontal__item
        grid-template-columns: unset
        grid-template-rows: max-content 0.5fr
        justify-content: center
        max-width: 408px
        margin: 0 auto

    .promotion-horizontal__item__title
        text-align: center

    .promotion-horizontal__item__ul li::before
        width: 10px
        height: 10px
