.better
    background-color: var(--color-bg)

.better__title
    margin-bottom: 80px

.better__wrap
    display: grid
    max-width: 1028px
    margin: 0 auto
    grid-template-columns: max-content max-content
    justify-content: space-between
    gap: 25px

.better__item
    display: grid
    max-width: 410px
    grid-template-columns: 100px 1fr
    gap: 20px
    align-items: center

.better__info
    display: grid
    grid-template-rows: 1fr max-content
    gap: 5px

.better__heading
    font-size: calc(16px + 4 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase
    color: var(--color-white)

.better__descriptor
    font-size: calc(16px + 4 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 400
    line-height: normal
    color: var(--color-white)

/* Фиксируем шрифты для больших экранов
@media (min-width: 1921px)
    .better__heading, .better__descriptor
        font-size: 20px

@media (max-width: 1000px)
    .better__title
        margin-bottom: 50px

@media (max-width: 900px)
    .better__wrap
        grid-template-columns: max-content
        justify-content: center
        gap: 32px

    .better__item
        grid-template-columns: 80px 1fr

    .better__info
        grid-template-rows: unset
        grid-auto-rows: max-content

@media (max-width: 565px)
    .better__wrap
        grid-template-columns: 1fr
        justify-content: center
        gap: 32px
        max-width: 335px

@media (max-width: 430px)
    .better__wrap
        max-width: 430px
