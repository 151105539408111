// Vars

$maxWidth: 1920

$accent: #ff5e1d
$blue: #3e9dcf

\:root
    --container: 1316px
    --offset: 80px
    --color-base-60: rgba(247, 152, 23, 0.6)
    --color-base: #F79817
    --color-black: #000000
    --color-black-90: rgba(0, 0, 0, 0.9)
    --color-black-80: rgba(0, 0, 0, 0.8)
    --color-black-70: rgba(0, 0, 0, 0.7)
    --color-bg: #000000
    --color-light: #FAF8EB
    --color-white: #FFFFFF
    --color-gray: #151515
    --color-white-80: rgba(255, 255, 255, 0.8)
    --color-white-70: rgba(255, 255, 255, 0.7)
    --button-height: 80px
    --button-sm-height: 75px
    --fr: calc((1216px - calc(20px * 11)) / 12)
    --gap: 20px
    --one-third: calc((var(--fr) * 4) + (var(--gap) * 3))
    --one-four: calc((var(--fr) * 3) + (var(--gap) * 2))

*
    box-sizing: border-box

