.contacts
    background-color: var(--color-light)

.contacts__title
    margin-bottom: 80px

.contacts__wrapper
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 20px

.contacts__item
    display: grid
    grid-template-rows: repeat(5, max-content) 0.5fr 0.5fr
    align-items: start
    gap: 15px
    border-radius: 8px
    background: var(--color-bg)
    padding: 0 0 15px

.contacts__item a
    text-decoration: none
    color: var(--color-black)

    &:hover
        color: var(--color-base)

.contacts__item__title
    padding: 17px
    min-height: 65px
    border-radius: 8px 8px 0px 0px
    background-color: var(--color-base)
    color: var(--color-black)
    text-align: center
    align-items: center
    font-size: calc(20px + 5 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 900
    line-height: normal
    text-transform: uppercase

.contacts__item__description
    color: var(--color-white)
    font-size: calc(16px + 2 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 400
    line-height: normal
    text-align: center

.contacts__item .button_sm
    height: 56px
    border-radius: 8px
    border: 0px

.button-contacts__item
    margin: 0 25px
    font-size: 20px
    font-style: normal
    font-weight: 800
    line-height: 1.1
    text-transform: uppercase

/*Contacts-top

.contacts_offset
    padding: var(--offset) 0 80px 0
    //padding: calc(var(--offset) + var(--header-height)) 0 80px 0 /*Если нет крошек*/

/* Фиксируем шрифты для больших экранов
@media (min-width: 1921px)
    .contacts__item__title
        font-size: 25px

    .contacts__item__description
        font-size: 18px

@media (max-width: 1000px)
    .contacts__title
        margin-bottom: 50px

    .contacts__wrapper
        grid-template-columns: unset
        grid-template-rows: repeat(3, 1fr)

    .contacts__item img
        max-width: 100%




//.contacts
//  //min-height: 100vh
//  //width: 100%
//  background-color: rgba(247, 152, 23, .1)
//
//  &__title
//    font-size: 60px
//    font-weight: 900
//    text-align: center
//    color: transparent
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 30px
//    padding-bottom: 10px
//
//    &__title_copy
//      font-size: 28px
//      font-weight: 900
//      text-align: center
//      color: black
//      -webkit-text-stroke-width: 0px
//      -webkit-text-stroke-color: transparent
//      position: relative
//      top: -55px
//
//  &__body
//    //min-height: 80vh
//    padding: 20px 0
//    display: flex
//    flex-direction: row
//    justify-content: space-around
//
//    &__item
//      border: 1px solid #F79817
//      border-radius: 5px
//      background-color: black
//      width: calc(95% / 3)
//
//      &__title
//        font-size: 25px
//        font-weight: 900
//        color: black
//        text-align: center
//        background-color: #F79817
//        text-transform: uppercase
//        padding: 10px 0
//
//      &__address
//        color: white
//        text-align: center
//        padding: 10px 0 5px 0
//
//      &__time
//        color: white
//        text-align: center
//        padding: 5px 0 5px 0
//
//      &__number
//        color: white
//        text-align: center
//        padding: 5px 0 10px 0
//
//      &__image
//        width: 100%
//        background-size: cover
//
//      &__route
//        background-color: #F79817
//        color: black
//        text-transform: uppercase
//        font-size: 20px
//        font-weight: 700
//        padding: 10px 0 10px 0
//        margin: 10px 10px 10px 10px
//        text-align: center
//        border-radius: 5px
//        cursor: pointer
//
//        &:hover
//          color: white
//
//      &__signup
//        background-color: #F79817
//        color: black
//        text-transform: uppercase
//        font-size: 20px
//        font-weight: 700
//        padding: 10px 0 10px 0
//        margin: 10px 10px 10px 10px
//        text-align: center
//        border-radius: 5px
//        cursor: pointer
//
//        &:hover
//          color: white
//
//
//
//
//
//
//
//
//
