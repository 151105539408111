.faq
  background-color: var(--color-light)

.faq__title
  margin-bottom: 20px

.faq__subtitle
  color: var(--color-black-80)
  font-size: calc(20px + 20 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 500
  line-height: normal
  margin-bottom: 50px

.faq-accordion
  display: grid
  gap: 15px
  margin-bottom: 15px

.faq-accordion__item
  display: grid
  grid-template-columns: 1fr
  justify-content: start
  align-items: center
  gap: 15px
  padding: 35px 10px 35px 0
  background-color: var(--color-light)
  overflow: hidden
  cursor: pointer
  border-bottom: 1px solid var(--color-black)

.faq-accordion__title
  display: grid
  grid-auto-flow: column
  gap: 20px
  justify-content: space-between
  align-items: center
  color: var(--color-black)
  font-size: calc(18px + 14 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 700
  line-height: normal

  &::after
    content: ""
    width: 40px
    height: 40px
    display: inline-block
    transition: 0.1s
    background-color: var(--color-base)
    padding: 20px
    border-radius: 50%
    background-image: url('../../../../assets/images/arrow.svg')
    background-position: center
    background-repeat: no-repeat
    background-size: 30px 30px
    //transition: 0.1s

.accordion__item--active .faq-accordion__title::after
  transform: rotate(180deg)

.faq-accordion__data
  padding: 0
  -webkit-transition: height 0.1s
  -o-transition: height 0.1s
  transition: height 0.1s
  overflow: hidden
  color: var(--color-black-80)
  font-size: calc(18px + 14 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 500
  line-height: normal

@media (min-width: 1921px)
  .faq__subtitle
    font-size: 40px

  .faq-accordion__title, .faq-accordion__data
    font-size: 32px

@media (max-width: 1000px)
  .faq__subtitle
    margin-bottom: 27px
    width: 100%
    text-align: center

@media (max-width: 680px)
  .faq-accordion
    gap: 0

  .faq-accordion__item
    padding: 18px 0

  .faq-accordion__title::after
    padding: 5px
    background-size: 15px 15px



//.faq
//  //min-height: 100vh
//  //width: 100%
//  background-color: rgba(247, 152, 23, .1)
//
//  &__title
//    font-size: 60px
//    font-weight: 900
//    text-align: center
//    color: transparent
//    -webkit-text-stroke-width: 2px
//    -webkit-text-stroke-color: #F79817
//    padding-top: 30px
//    padding-bottom: 10px
//    text-transform: uppercase
//
//    &__title_copy
//      font-size: 28px
//      font-weight: 900
//      text-align: center
//      color: black
//      -webkit-text-stroke-width: 0px
//      -webkit-text-stroke-color: transparent
//      position: relative
//      top: -55px
//      text-transform: uppercase
//
//  &__subtitle
//    font-size: 30px
//    font-weight: 500
//    text-align: center
//
//  &__body
//    //min-height: 80vh
//    padding: 20px 0 50px 0
//    display: flex
//    flex-direction: row
//    justify-content: space-between
//    flex-wrap: wrap
//
//    &__item
//      //padding: 15px
//      display: flex
//      flex-direction: column
//      //margin-bottom: 5px
//      width: 100%
//      cursor: pointer
//
//      &__line
//        display: flex
//        flex-direction: row
//        justify-content: space-between
//        padding-right: 30px
//        width: 100%
//        flex-basis: 100%
//
//        &__title
//          font-size: 18px
//          font-weight: 600
//          color: black
//          margin-top: auto
//          margin-bottom: auto
//          //margin-top: 20px
//          //margin-bottom: 15px
//          //float: left
//          //margin-right: 25px
//
//        &__arrow
//          width: 55px
//          height: 55px
//          background-color: #F79817
//          float: right
//          border-radius: 50%
//          display: flex
//
//          &#{&} img
//            display: block
//            margin: auto
//
//      &#{&}  hr
//        margin: 10px 0 10px 0
//        border-color: black
//
//      &__description
//        width: 100%
//        height: auto
//        //flex-basis: 100%
//        display: none
//
//
//
//
//
//
