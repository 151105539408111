.question
  background-color: var(--color-bg)

  input
    height: 80px

.question__item
  display: grid
  grid-template-rows: repeat(2, 0.5fr)
  gap: 20px

.question__title
  color: var(--color-base)
  font-family: Montserrat
  font-size: calc(25px + 15 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 700
  text-transform: uppercase
  margin: unset

.question__description
  color: var(--color-white-80)
  font-family: Montserrat
  font-size: calc(20px + 12 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 500
  line-height: normal
  margin-bottom: 30px

.question__block
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 20px

.question__name
  border-radius: 12px
  border: 1px solid var(--color-white-70)
  background: var(--color-gray)
  color: rgba(255, 255, 255, 0.7)
  font-family: Montserrat
  font-size: calc(18px + 2 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 500
  line-height: normal
  text-transform: uppercase
  align-content: center

.question__phone
  border-radius: 12px
  border: 1px solid var(--color-white-70)
  background: var(--color-gray)
  color: var(--color-white-70)
  font-family: Montserrat
  font-size: calc(18px + 2 * ((100vw - 375px) / (1920 - 375)))
  font-style: normal
  font-weight: 500
  line-height: normal
  text-transform: uppercase

@media (min-width: 1921px)
  .question__title
    font-size: 40px

  .question__description
    font-size: 32px

  .question__name, .question__phone
    font-size: 20px

@media (max-width: 1000px)
  .question__block
    grid-template-columns: unset
    grid-template-rows: repeat(3, max-content)



//.any_question
//  background-color: black
//
//  &__body
//    display: flex
//    flex-direction: column
//    justify-content: space-around
//    height: 250px
//
//    &__title
//      font-size: 35px
//      color: #F79817
//      font-weight: 700
//      text-transform: uppercase
//      margin-top: 30px
//
//    &__subtitle
//      font-size: 20px
//      color: white
//      font-weight: 500
//
//    &__line
//      display: flex
//      flex-direction: row
//      justify-content: space-between
//      flex-wrap: wrap
//      padding-bottom: 30px
//
//      &__item
//        //width: 50%
//        flex-basis: 31%
//        //margin-bottom: 15px
//        border-radius: 5px
//        font-size: 16px
//        padding: 13px 5px 13px 5px
//
//        &[type="text"]
//          background-color: black
//          border: 1px solid #F79817
//          padding-left: 15px
//          color: white
//
//        &::placeholder
//          color: white
//
//        &__button
//          background-color: #F79817
//          font-weight: 600
//          text-align: center
//          cursor: pointer
//
//          &:hover
//            color: white
//
//
//
//
//
