.example
    position: relative
    background-color: var(--color-bg)

.example_offset
    padding: var(--offset) 0 80px 0

.example__title
    margin-bottom: 90px

.example-swiper__inner
    display: grid
    width: 100%
    grid-template-columns: 1fr max-content
    gap: 34px

.example-swiper__info
    display: grid
    gap: 30px
    grid-auto-rows: max-content
    align-items: start
    color: var(--color-base)

.example-swiper__title
    margin: 0
    font-size: 32px
    font-weight: 700

.example-swiper__works
    display: grid
    gap: 30px

.example-swiper__works-label
    font-size: calc(18px + 7 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 600
    color: var(--color-white)

.example-swiper__works-list
    padding: 0
    font-size: calc(18px + 5 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 500
    list-style-type: none
    display: grid
    gap: 17px

.example-swiper__works-item
    display: grid
    grid-template-columns: 1fr max-content
    gap: 20px

.example-swiper__works-name
    text-decoration: underline

.example-swiper__works-price
    color: var(--color-white)

.example-swiper__works-descriptor
    font-size: calc(18px + 7 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 500
    color: var(--color-white-80)

.example-swiper__price
    display: grid
    grid-template-columns: max-content max-content
    gap: 10px
    align-items: center

.example-swiper__price-label
    font-size: calc(16px + 9 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 600
    color: var(--color-white)

.example-swiper__price-value
    font-size: calc(18px + 12 * ((100vw - 375px) / (1920 - 375)))
    font-weight: 800

.example-swiper__gallery
    width: 100%
    min-width: 0
    max-width: 578px
    padding-top: 32px


.swiper-gallery
    margin-bottom: 20px
    border-radius: 8px
    overflow: hidden

.swiper-gallery__wrap
    position: relative

.swiper-gallery__item img
    display: block
    max-height: 385px
    height: max-content
    width: 100%
    max-width: 578px

.swiper-gallery-slave__item
    border-radius: 8px
    overflow: hidden

    img
        display: block

.example-swiper__prev, .example-swiper__next
    width: 100px
    height: 100px
    border-radius: 50%
    background-color: var(--color-gray)
    border: solid 2px var(--color-base)
    color: var(--color-base, var(--color-base))

.example-swiper__prev
    left: 64px

.example-swiper__next
    right: 64px

.swiper-gallery__prev, .swiper-gallery__next
    --swiper-navigation-size: 25px
    width: 50px
    height: 50px
    top: unset
    bottom: 0
    border-radius: 8px
    background-color: var(--color-base)
    border: solid 2px var(--color-base)
    color: var(--color-white, var(--color-white))
    z-index: 1

.swiper-gallery__prev
    left: 0
    border-radius: 0 8px 0 8px

.swiper-gallery__next
    right: 0
    border-radius: 8px 0 8px 0

/* Фиксируем шрифты для больших экранов
@media (min-width: 1921px)
    .example-swiper__price-value
        font-size: 30px

    .example-swiper__works-label, .example-swiper__works-descriptor, .example-swiper__price-label
        font-size: 25px

    .example-swiper__works-list
        font-size: 23px

@media (max-width: 1550px)
    .example-swiper__next, .example-swiper__prev
        opacity: 0.7
        width: 75px
        height: 75px


@media (max-width: 1240px)

@media (max-width: 1140px)
    .example-swiper__next, .example-swiper__prev
        display: none

    .example__title
        margin-bottom: 50px

        .title__bg
            max-width: calc(100vw - 40px)
            text-align: center
            margin-bottom: 0

    .example-swiper__inner
        grid-template-columns: 1fr

    .swiper-gallery
        margin-bottom: 11px

    .example-swiper__gallery
        order: -1
        padding-top: 0

