.our-works
    background-color: var(--color-bg)
    padding: var(--offset) 0 80px 0
    //padding: calc(var(--offset) + var(--header-height)) 0 80px 0 /*Нужен без крошек*/

.our-works__wrapper
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 20px

.our-works__title
    margin-bottom: 80px

.our-works__item
    display: grid
    //grid-template-rows: repeat(4, max-content) /*Описание и More*/
    grid-template-rows: repeat(2, max-content)
    align-items: center
    gap: 20px
    padding: 20px
    border-radius: 8px
    border: 2px solid var(--color-base)
    background: var(--color-gray)

.our-works__item__image img
    border-radius: 8px
    display: block

.our-works__item__title
    color: var(--color-base)
    font-size: calc(20px + 5 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 800
    line-height: normal
    text-align: center

.our-works__item__description
    color: var(--color-white)
    font-size: calc(18px + 4 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 500
    line-height: normal

.our-works__item__more
    color: var(--color-base)
    font-size: calc(18px + 7 * ((100vw - 375px) / (1920 - 375)))
    font-style: normal
    font-weight: 400
    line-height: normal

.button__more
    margin-top: 80px

@media (min-width: 1921px)
    .our-works__item__title, .our-works__item__more
        font-size: 25px

    .our-works__item__description
        font-size: 22px

@media (max-width: 1000px)
    .our-works__title
        margin-bottom: 50px

    .our-works__wrapper
        grid-template-columns: 1fr 1fr
        justify-content: center
        gap: 20px

    .our-works__item:last-child
        grid-column: 1 / -1
        margin: 0 auto
        max-width: max-content

@media (max-width: 700px)
    .our-works__wrapper
        grid-template-columns: 1fr

//@media (max-width: 374px)
//    .our-works__title .title__bg_black
//        font-size: 28px
